import React from "react";
import { motion } from "framer-motion";
import { Typography, Box } from "@mui/material";
// import { useTheme } from "@mui/material/styles";
import { getSvgUrl } from "../../../utils";
import { formatDistance, formatTime, convertDistance } from "../TrainingRecap";

const RaceSlide = ({ recapData, useKilometers }) => {
  //   const theme = useTheme();
  const TrophySvgUrl = getSvgUrl("trophy");
  const FlagSvgUrl = getSvgUrl("flag");
  const MeterSvgUrl = getSvgUrl("meter");
  const HeartRateSvgUrl = getSvgUrl("heart_rate");
  const ConnectedSvgUrl = getSvgUrl("connected");
  const stravaOrange = process.env.REACT_APP_STRAVA_ORANGE;

  const calculateAveragePace = () => {
    const totalDistanceInKm = recapData.races[0].distance / 1000;
    const totalTimeInMinutes = recapData.races[0].elapsed_time / 60;

    let pacePerUnit;
    if (useKilometers) {
      pacePerUnit = totalTimeInMinutes / totalDistanceInKm;
    } else {
      const totalDistanceInMiles = totalDistanceInKm * 0.621371;
      pacePerUnit = totalTimeInMinutes / totalDistanceInMiles;
    }

    const paceMinutes = Math.floor(pacePerUnit);
    const paceSeconds = Math.round((pacePerUnit - paceMinutes) * 60);

    return `${paceMinutes}:${paceSeconds.toString().padStart(2, "0")} /${
      useKilometers ? "km" : "mi"
    }`;
  };

  const BestEffortsHighlights = ({ bestEfforts }) => {
    const personalRecords = bestEfforts.filter(
      (effort) => effort.pr_rank === 1
    );

    const HighlightItem = ({ iconUrl, text }) => (
      <motion.div
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.5 }}
      >
        <Box sx={{ display: "flex", alignItems: "center", mb: 1 }}>
          <img
            src={iconUrl}
            alt="Icon"
            style={{
              width: "20px",
              height: "20px",
              marginRight: "8px",
              filter: stravaOrange,
            }}
          />
          <Typography variant="body1">{text}</Typography>
        </Box>
      </motion.div>
    );

    return (
      <Box sx={{ mt: 2 }}>
        <Typography variant="h6" gutterBottom align="center">
          Your Outstanding Achievements
        </Typography>
        {personalRecords.map((pr) => (
          <HighlightItem
            key={pr.name}
            iconUrl={TrophySvgUrl} // Pass the SVG URL here
            text={`New Personal Record for ${pr.name}: ${formatTime(
              pr.elapsed_time
            )}`}
          />
        ))}
      </Box>
    );
  };

  const CelebrationItem = ({ icon, title, value }) => (
    <Box sx={{ display: "flex", alignItems: "center", mb: 2 }}>
      <Box sx={{ mr: 2 }}>
        <img
          src={icon}
          alt={title}
          style={{ width: "30px", height: "30px", filter: stravaOrange }}
        />
      </Box>
      <Box>
        <Typography variant="body2" color="textSecondary">
          {title}
        </Typography>
        <Typography variant="h6">{value}</Typography>
      </Box>
    </Box>
  );

  return (
    <Box
      sx={{
        width: "100%",
        height: "100%",
        display: "flex",
        flexDirection: "column",
      }}
    >
      <Box
        sx={{
          flexGrow: 1,
          overflowY: "auto",
          padding: "25px",
        }}
      >
        <Box sx={{ padding: "25px" }}>
          <motion.div
            initial={{ y: -50, opacity: 0 }}
            animate={{ y: 0, opacity: 1 }}
            transition={{ duration: 0.5 }}
          >
            <Typography variant="h3" color="primary" gutterBottom>
              You Conquered the Race!
            </Typography>
          </motion.div>

          <Box sx={{ position: "relative", my: 2 }}>
            <Box
              component="img"
              src={TrophySvgUrl}
              sx={{
                width: 100,
                height: 100,
                opacity: 0.2,
                position: "absolute",
                top: "50%",
                left: "50%",
                transform: "translate(-50%, -50%)",
                filter: stravaOrange,
              }}
            />
            <motion.div
              initial={{ scale: 0 }}
              animate={{ scale: 1 }}
              transition={{ type: "spring", stiffness: 260, damping: 20 }}
            >
              <Typography
                variant="h1"
                align="center"
                sx={{ position: "relative", zIndex: 1 }}
              >
                {formatTime(recapData.races[0].moving_time)}
              </Typography>
            </motion.div>
          </Box>

          <Typography variant="h5" align="center" gutterBottom>
            Official Finish Time
          </Typography>
          <Typography variant="h5" align="center" gutterBottom>
            {recapData.races[0].name}
          </Typography>
          <Typography
            variant="body1"
            align="center"
            sx={{ mt: 2, fontStyle: "italic" }}
          >
            {recapData.races[0].description ||
              "Congratulations on your amazing achievement!"}
          </Typography>

          <Box sx={{ mt: 3 }}>
            <CelebrationItem
              icon={FlagSvgUrl}
              title="Distance"
              value={formatDistance(
                convertDistance(recapData.races[0].distance, useKilometers),
                useKilometers
              )}
            />
            <CelebrationItem
              icon={MeterSvgUrl}
              title="Average Pace"
              value={calculateAveragePace()}
            />
            <CelebrationItem
              icon={HeartRateSvgUrl}
              title="Average Heart Rate"
              value={`${recapData.races[0].average_heartrate} bpm`}
            />
            <CelebrationItem
              icon={ConnectedSvgUrl}
              title="Elevation Gain"
              value={`${recapData.races[0].total_elevation_gain} m`}
            />
          </Box>

          <BestEffortsHighlights
            bestEfforts={JSON.parse(recapData.races[0].best_efforts)}
          />
        </Box>
        {/* Clear area for controls */}
        <Box
          sx={{
            position: "absolute",
            bottom: 0,
            left: 0,
            right: 0,
            height: 100,
          }}
        />
      </Box>
    </Box>
  );
};

export default RaceSlide;
