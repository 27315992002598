import axios from "axios";
import { datadogRum } from '@datadog/browser-rum';

const instance = axios.create({
  // baseURL: "http://localhost:8000",
  // baseURL: "strava-wrapped-alb-253390457.us-east-1.elb.amazonaws.com",
  baseURL: "stravawrapped.com",
  timeout: 30000,
  headers: {
    "Content-Type": "application/json",
    Accept: "application/json",
  },
});

// Add a request interceptor
instance.interceptors.request.use((config) => {
  // Add Datadog RUM tracing headers to every request
  config.headers = {
    ...config.headers,
    ...datadogRum.getInternalContext().traceContextHeaders
  };
  return config;
}, (error) => {
  // Do something with request error
  return Promise.reject(error);
});

export default instance;
