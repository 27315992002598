import React, { useState, useEffect, useMemo } from "react";
import { motion, AnimatePresence } from "framer-motion";
import { Typography, Avatar, Box, Paper } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import CommentSVG from "../../../strava_icons_svg/comment.svg";
import HeartSVG from "../../../strava_icons_svg/heart_rate.svg";
import { getSvgUrl } from "../../../utils";

const CommentsSlide = ({ recapData, isVisible }) => {
  const theme = useTheme();
  const [displayedComments, setDisplayedComments] = useState([]);
  const [randomComments, setRandomComments] = useState([]);
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);
  const [screenHeight, setScreenHeight] = useState(window.innerHeight);
  const stravaOrange = process.env.REACT_APP_STRAVA_ORANGE;
  const stravaLightGrey = process.env.REACT_APP_STRAVA_LIGHT_GREY;
  const CommentSvgUrl = getSvgUrl("comment");
  //   const HeartSvgUrl = getSvgUrl(HeartSVG);

  useEffect(() => {
    const handleResize = () => {
      setScreenWidth(window.innerWidth);
      setScreenHeight(window.innerHeight);
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  useEffect(() => {
    if (isVisible) {
      const updateComments = () => {
        const shuffledComments = [...recapData.comments_info.all_comments].sort(
          () => 0.5 - Math.random()
        );
        setDisplayedComments(shuffledComments.slice(0, 20));
        // setRandomComments(shuffledComments.slice(0, 55));
      };

      updateComments();
      const interval = setInterval(updateComments, 5000);
      return () => clearInterval(interval);
    }
  }, [isVisible, recapData.comments_info.all_comments]);

  const commentPositions = useMemo(() => {
    return Array.from({ length: 50 }, () => ({
      x: Math.random() * screenWidth,
      y: Math.random() * screenHeight,
      size: Math.random() * 50 + 20,
      delay: Math.random() * 2,
    }));
  }, [screenWidth, screenHeight]);

  return (
    <Box
      sx={{
        width: "100%",
        height: "100%",
        position: "relative",
        overflow: "hidden",
        padding: "25px",
      }}
    >
      {/* Background Pattern */}
      <Box
        sx={{
          position: "absolute",
          top: "-50%",
          left: "-50%",
          width: "500%",
          height: "500%",
          backgroundImage: CommentSvgUrl,
          backgroundSize: "30px 30px",
          backgroundRepeat: "repeat",
          opacity: 0.08,
          filter: stravaLightGrey,
          transform: "rotate(-45deg)",
          zIndex: 0,
        }}
      />

      {/* Main Content */}
      <Box
        sx={{
          position: "relative",
          zIndex: 1,
          padding: "20px",
          height: "calc(100% - 100px)",
          display: "flex",
          flexDirection: "column",
        }}
      >
        <Typography variant="h3" gutterBottom color="primary">
          Conversations That Kept You Going
        </Typography>
        <Typography variant="h4" gutterBottom align="center">
          {recapData.comments_info.total_comments} Comments
        </Typography>
        <Typography variant="h5" gutterBottom align="center">
          From your most vocal supporters:
        </Typography>
        <Box
          sx={{
            mb: 2,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          {recapData.comments_info.top_commenters
            .slice(0, 5)
            .map((commenter, index) => (
              <Box
                key={index}
                sx={{ display: "flex", alignItems: "center", mt: 1 }}
              >
                <Avatar sx={{ bgcolor: "background.paper", mr: 2 }}>
                  <Box
                    component="img"
                    src={HeartSVG}
                    sx={{ width: 24, height: 24, filter: stravaOrange }}
                  />
                </Avatar>
                <Typography variant="h6">
                  {commenter.name} - {commenter.count} Comments
                </Typography>
              </Box>
            ))}
        </Box>

        {/* Scrollable Comments Container */}
        <Box sx={{ flexGrow: 1, overflow: "hidden", position: "relative" }}>
          <motion.div
            animate={{ y: [0, -displayedComments.length * 60] }}
            transition={{
              y: { duration: 20, repeat: Infinity, ease: "linear" },
            }}
          >
            {displayedComments.map((comment, index) => (
              <Paper
                key={index}
                elevation={3}
                sx={{
                  p: 2,
                  m: 1,
                  maxWidth: "100%",
                  backgroundColor: theme.palette.background.paper,
                  borderLeft: `4px solid ${stravaOrange}`,
                }}
              >
                <Typography variant="body1" gutterBottom>
                  "{comment.comment_text}"
                </Typography>
                <Typography variant="caption" color="textSecondary">
                  - {comment.commenter_name}
                </Typography>
              </Paper>
            ))}
          </motion.div>
        </Box>
      </Box>

      {/* Floating Comment SVGs */}
      <AnimatePresence>
        {isVisible &&
          commentPositions.map((pos, index) => (
            <motion.img
              key={index}
              src={CommentSVG}
              alt="Comment"
              style={{
                position: "absolute",
                width: `${pos.size}px`,
                height: `${pos.size}px`,
                top: `${pos.y}px`,
                left: `${pos.x}px`,
                filter: stravaOrange,
                zIndex: 0,
                pointerEvents: "none",
              }}
              initial={{ opacity: 0, scale: 0 }}
              animate={{ opacity: 0.3, scale: 1 }}
              exit={{ opacity: 0, scale: 0 }}
              transition={{
                delay: pos.delay,
                duration: 4,
                repeat: Infinity,
                repeatType: "reverse",
              }}
            />
          ))}
      </AnimatePresence>

      {/* Clear area for controls */}
      <Box
        sx={{ position: "absolute", bottom: 0, left: 0, right: 0, height: 100 }}
      />
    </Box>
  );
};

export default CommentsSlide;
