import { createTheme } from "@mui/material/styles";

// Define the primary, secondary, and other colors
const stravaColors = {
  primary: "#FC4C02", // Strava orange
  secondary: "#FF7043", // Softer orange for secondary actions
  tertiary: "#8884d8", // Light blue/purple for charts and visualizations
  warning: "#E64501", // Alert/Warning color
  success: "#82ca9d", // Success green for positive outcomes
  complementary: "#FFBB28", // Bright yellow for averages and less critical data
  coolCyan: "#40E0D0", // Cool cyan for secondary metrics
  softPurple: "#8A2BE2", // Soft purple for complex visualizations
  accentTeal: "#20B2AA", // Teal for highlighting or calmer data points
  neutral: "#A9A9A9", // Neutral gray for baselines and subtle elements
  textPrimary: "#242428", // Dark gray for primary text
  textSecondary: "#6D6D78", // Medium gray for secondary text
  divider: "#E6E6EB", // Light gray for dividers and borders
  backgroundPaper: "#FFFFFF", // Default background
  backgroundDefault: "#F7F7FA", // Paper background
  // backgroundDefault: "#FFFFFF", // Default background
  // backgroundPaper: "#F7F7FA", // Paper background
  contrastText: "#FFFFFF",
};

// Define typography settings
const stravaTypography = {
  fontFamily: '"Helvetica Neue", "Roboto", "Helvetica", "Arial", sans-serif',
  h1: {
    fontSize: "2.25rem",
    fontWeight: 700,
  },
  h2: {
    fontSize: "2rem",
    fontWeight: 700,
  },
  h3: {
    fontSize: "1.75rem",
    fontWeight: 600,
  },
  h4: {
    fontSize: "1.5rem",
    fontWeight: 600,
  },
  h5: {
    fontSize: "1.25rem",
    fontWeight: 500,
  },
  h6: {
    fontSize: "1rem",
    fontWeight: 500,
  },
  body1: {
    fontSize: ".95rem",
    fontWeight: 400,
  },
  body2: {
    fontSize: "0.85rem",
    fontWeight: 400,
  },
  button: {
    textTransform: "none",
    fontWeight: 600,
    fontSize: "0.875rem",
  },
  caption: {
    fontSize: "0.75rem",
    fontWeight: 400,
  },
  overline: {
    fontSize: "0.75rem",
    fontWeight: 700,
    letterSpacing: 1,
    textTransform: "uppercase",
  },
};

// Define custom spacing units
const customSpacing = {
  mobilePadding: "8px 12px",
  desktopPadding: "12px 16px",
};

// Define component overrides
const componentOverrides = {
  MuiButton: {
    styleOverrides: {
      root: {
        borderRadius: 4,
        textTransform: "none",
        fontWeight: 600,
        padding: "8px 16px",
        "&:hover": {
          backgroundColor: stravaColors.warning,
        },
      },
      contained: {
        boxShadow: "none",
        "&:hover": {
          boxShadow: "none",
          backgroundColor: stravaColors.warning,
        },
      },
    },
  },
  MuiCard: {
    styleOverrides: {
      root: {
        borderRadius: 8,
        boxShadow: "0 2px 8px rgba(0, 0, 0, 0.05)",
        padding: "16px",
      },
    },
  },
  MuiTypography: {
    styleOverrides: {
      root: {
        color: stravaColors.textPrimary,
      },
    },
  },
  MuiListItemText: {
    styleOverrides: {
      primary: {
        color: stravaColors.textPrimary,
        fontWeight: 600,
      },
      secondary: {
        color: stravaColors.textSecondary,
        fontWeight: 400,
      },
    },
  },
  MuiChip: {
    styleOverrides: {
      root: {
        borderRadius: 16,
        fontWeight: 500,
        backgroundColor: stravaColors.backgroundPaper,
      },
    },
  },
  MuiSvgIcon: {
    styleOverrides: {
      root: {
        color: stravaColors.primary,
      },
    },
  },
  MuiPaper: {
    styleOverrides: {
      root: {
        boxShadow: "0px 1px 3px rgba(0, 0, 0, 0.12)",
      },
    },
  },
};

// Create the theme
const stravaTheme = createTheme({
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 960,
      lg: 1280,
      xl: 1920,
    },
  },
  palette: {
    primary: {
      main: stravaColors.primary,
      contrastText: stravaColors.contrastText,
    },
    secondary: {
      main: stravaColors.secondary,
      contrastText: stravaColors.textPrimary,
    },
    tertiary: {
      main: stravaColors.tertiary,
      contrastText: stravaColors.contrastText,
    },
    warning: {
      main: stravaColors.warning,
      contrastText: stravaColors.contrastText,
    },
    success: {
      main: stravaColors.success,
      contrastText: stravaColors.contrastText,
    },
    complementary: {
      main: stravaColors.complementary,
      contrastText: stravaColors.textPrimary,
    },
    coolCyan: {
      main: stravaColors.coolCyan,
      contrastText: stravaColors.textPrimary,
    },
    softPurple: {
      main: stravaColors.softPurple,
      contrastText: stravaColors.contrastText,
    },
    accentTeal: {
      main: stravaColors.accentTeal,
      contrastText: stravaColors.textPrimary,
    },
    neutral: {
      main: stravaColors.neutral,
      contrastText: stravaColors.contrastText,
    },
    background: {
      default: stravaColors.backgroundDefault,
      paper: stravaColors.backgroundPaper,
    },
    text: {
      primary: stravaColors.textPrimary,
      secondary: stravaColors.textSecondary,
    },
    grey: {
      100: stravaColors.backgroundPaper,
      200: stravaColors.divider,
      300: "#D1D1D7",
      400: "#ACACB3",
      500: stravaColors.textSecondary,
    },
  },
  typography: stravaTypography,
  shape: {
    borderRadius: 4,
  },
  spacing: 8, // Base spacing unit
  components: componentOverrides,
  customSpacing: customSpacing,
  shadows: Array(25)
    .fill("none")
    .map((_, index) =>
      index === 1 ? "0px 1px 3px rgba(0, 0, 0, 0.12)" : "none"
    ),
  chartStyles: {
    line: {
      strokeWidth: 2,
      dotSize: 3,
      activeDotSize: 6,
    },
    colors: {
      distance: stravaColors.primary,
      pace: stravaColors.warning,
      longRun: stravaColors.tertiary,
      average: stravaColors.complementary,
      success: stravaColors.success,
      recovery: stravaColors.coolCyan,
      heartRate: stravaColors.softPurple,
      baseline: stravaColors.neutral,
      highlight: stravaColors.accentTeal,
    },
  },
});

export default stravaTheme;

// import { createTheme } from "@mui/material/styles";

// const stravaTheme = createTheme({
//   breakpoints: {
//     values: {
//       xs: 0,
//       sm: 600,
//       md: 960,
//       lg: 1280,
//       xl: 1920,
//     },
//   },
//   palette: {
//     primary: {
//       main: "#FC4C02", // Strava orange
//       contrastText: "#FFFFFF",
//     },
//     secondary: {
//       main: "#FF7043", // Softer orange for secondary actions
//       contrastText: "#242428",
//     },
//     tertiary: {
//       main: "#8884d8", // Light blue/purple for charts
//       contrastText: "#FFFFFF",
//     },
//     warning: {
//       main: "#E64501", // Alert/Warning color
//       contrastText: "#FFFFFF",
//     },
//     background: {
//       default: "#FFFFFF",
//       paper: "#F7F7FA",
//     },
//     text: {
//       primary: "#242428",
//       secondary: "#6D6D78",
//     },
//     grey: {
//       100: "#F7F7FA",
//       200: "#E6E6EB",
//       300: "#D1D1D7",
//       400: "#ACACB3",
//       500: "#6D6D78",
//     },
//   },
//   typography: {
//     fontFamily: '"Helvetica Neue", "Roboto", "Helvetica", "Arial", sans-serif',
//     h1: {
//       fontSize: "2.25rem",
//       fontWeight: 700,
//     },
//     h2: {
//       fontSize: "2rem",
//       fontWeight: 700,
//     },
//     h3: {
//       fontSize: "1.75rem",
//       fontWeight: 600,
//     },
//     h4: {
//       fontSize: "1.5rem",
//       fontWeight: 600,
//     },
//     h5: {
//       fontSize: "1.25rem",
//       fontWeight: 500,
//     },
//     h6: {
//       fontSize: "1rem",
//       fontWeight: 500,
//     },
//     body1: {
//       fontSize: ".95rem",
//       fontWeight: 400,
//     },
//     body2: {
//       fontSize: "0.85rem",
//       fontWeight: 400,
//     },
//     button: {
//       textTransform: "none",
//       fontWeight: 600, // Bolder buttons
//       fontSize: "0.875rem", // Ensure buttons text is a bit smaller for better fit
//     },
//     caption: {
//       fontSize: "0.75rem",
//       fontWeight: 400,
//     },
//     overline: {
//       fontSize: "0.75rem",
//       fontWeight: 700,
//       letterSpacing: 1,
//       textTransform: "uppercase",
//     },
//   },
//   shape: {
//     borderRadius: 4,
//   },
//   spacing: 8, // Base spacing unit
//   components: {
//     MuiButton: {
//       styleOverrides: {
//         root: {
//           borderRadius: 4,
//           textTransform: "none",
//           fontWeight: 500,
//           padding: (props) =>
//             `${props.theme.spacing(1)}px ${props.theme.spacing(2)}px`,
//           "&:hover": {
//             boxShadow: "none",
//           },
//         },
//         contained: {
//           boxShadow: "none",
//           "&:hover": {
//             boxShadow: "none",
//             backgroundColor: "#E64501",
//           },
//         },
//       },
//     },
//     MuiCard: {
//       styleOverrides: {
//         root: {
//           borderRadius: 8,
//           boxShadow: "0 2px 8px rgba(0, 0, 0, 0.05)",
//           padding: "16px",
//         },
//       },
//     },
//     MuiAvatar: {
//       variants: [
//         {
//           props: { variant: "profile" },
//           style: {
//             width: 100,
//             height: 100,
//             boxShadow: "0 0 0 2px #FC4C02",
//           },
//         },
//       ],
//     },
//     MuiChip: {
//       styleOverrides: {
//         root: {
//           borderRadius: 16,
//           fontWeight: 500, // Bolder chip text
//           backgroundColor: "#F7F7FA",
//         },
//       },
//     },
//     MuiListItem: {
//       styleOverrides: {
//         root: {
//           paddingTop: 6,
//           paddingBottom: 6,
//         },
//       },
//     },
//     MuiDivider: {
//       styleOverrides: {
//         root: {
//           margin: "16px 0",
//           backgroundColor: "#E6E6EB",
//         },
//       },
//     },
//   },
//   customSpacing: {
//     mobilePadding: "8px 12px",
//     desktopPadding: "12px 16px",
//   },
// });

// export default stravaTheme;

// import { createTheme } from "@mui/material/styles";

// const theme = createTheme({
//   palette: {
//     primary: {
//       // main: "#F54B05", // Strava Orange color from the mockups
//       main: "#FC4C02", // Strava orange
//       contrastText: "#FFFFFF",
//     },
//     secondary: {
//       // main: "#2196F3", // Blue color for accents
//       // main: "#9B9B9B", // Strava Grey color for accents
//       main: "#FFFFFF",
//       contrastText: "#000000",
//     },
//     background: {
//       default: "#FFFFFF",
//       paper: "#F5F5F5",
//     },
//   },
//   typography: {
//     // fontFamily: '"Helvetica Neue", "Roboto", "Helvetica", "Arial", sans-serif',
//     fontFamily: '"Helvetica Neue"',
//     h1: {
//       fontSize: "2.5rem",
//       fontWeight: 500,
//     },
//     h2: {
//       fontSize: "2rem",
//       fontWeight: 500,
//     },
//     h3: {
//       fontSize: "1.75rem",
//       fontWeight: 500,
//     },
//     h4: {
//       fontSize: "1.5rem",
//       fontWeight: 500,
//     },
//     h5: {
//       fontSize: "1.25rem",
//       fontWeight: 500,
//     },
//     h6: {
//       fontSize: "1rem",
//       fontWeight: 500,
//     },
//     body1: {
//       fontSize: "1rem",
//     },
//     body2: {
//       fontSize: "0.875rem",
//     },
//     button: {
//       textTransform: "none", // This prevents automatic uppercase transformation
//     },
//     caption: {
//       fontSize: "0.75rem",
//     },
//     overline: {
//       fontSize: "0.75rem",
//       fontWeight: 700,
//       letterSpacing: 1,
//       textTransform: "uppercase",
//     },
//   },
//   components: {
//     MuiButton: {
//       styleOverrides: {
//         root: {
//           borderRadius: 8,
//         },
//       },
//     },
//     MuiCard: {
//       styleOverrides: {
//         root: {
//           borderRadius: 12,
//           boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
//         },
//       },
//     },
//   },
// });

// export default theme;
