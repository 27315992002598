import React, {
  createContext,
  useContext,
  useState,
  useRef,
  useCallback,
} from "react";

const TrainingRecapContext = createContext();

export const useTrainingRecap = () => useContext(TrainingRecapContext);

export const TrainingRecapProvider = ({ children }) => {
  const [recapData, setRecapData] = useState(null);
  const [error, setError] = useState(null);
  const [errorInsight, setErrorInsight] = useState(null);
  const [loading, setLoading] = useState(true);
  const [loadingInsights, setLoadingInsights] = useState(null);
  const [useKilometers, setUseKilometers] = useState(false);
  const [filterData, setFilterData] = useState(null);
  const [aiInsight, setAiInsight] = useState(null);

  const baseApiUrl = process.env.REACT_APP_API_URL;

  const fetchRecapData = useCallback(async (filterData) => {
    // // const fetchRecapData = useCallback(async () => {
    // console.log(filterData);
    // console.log("contexts");
    const { strava_id, startDate, endDate } = filterData;

    setLoading(true);
    setError(null);
    // console.log(baseApiUrl);
    // console.log("base api url");
    try {
      const response = await fetch(
        `${baseApiUrl}/api/training-recap?userId=${strava_id}&startDate=${startDate}&endDate=${endDate}`
      );
      const data = await response.json();
      console.log(data);
      setRecapData(data);
    } catch (error) {
      console.error("Error fetching recap data:", error);
      setError(error);
    } finally {
      setLoading(false);
    }
  }, []);

  const fetchRecapAnalysis = useCallback(async (recapData) => {
    // console.log("fetchRecapAnalysis called");
    const requestBody = {
      runner_data: recapData,
    };
    // console.log("Request body:", JSON.stringify(requestBody, null, 2));
    setLoadingInsights(true);
    setErrorInsight(null);

    try {
      // const response = await fetch(`/api/training-recap-analysis/`, {
      const response = await fetch(
        `${baseApiUrl}/api/training-recap-analysis/`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(requestBody),
        }
      );

      // console.log("Response status:", response.status);

      const responseText = await response.text();
      const data = JSON.parse(responseText);

      if (!response.ok) {
        throw new Error(
          `HTTP error! status: ${response.status}, body: ${responseText}`
        );
      }
      console.log(data.token_counts);
      setAiInsight(data);
      return data;
    } catch (error) {
      console.error("Error in fetchRecapAnalysis:", error);
      setErrorInsight(true);
      throw error;
    } finally {
      setLoadingInsights(false);
    }
  });

  const toggleUnits = () => setUseKilometers(!useKilometers);

  return (
    <TrainingRecapContext.Provider
      value={{
        recapData,
        aiInsight,
        error,
        errorInsight,
        loading,
        loadingInsights,
        useKilometers,
        setUseKilometers,
        filterData,
        setFilterData,
        fetchRecapData,
        fetchRecapAnalysis,
        toggleUnits,
      }}
    >
      {children}
    </TrainingRecapContext.Provider>
  );
};
