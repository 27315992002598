import React, { useCallback } from "react";
import { motion } from "framer-motion";
import { Typography, Box, Stack } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { getSvgUrl } from "../../../utils";
import {
  formatDistance,
  formatTime,
  formatPace,
  convertDistance,
} from "../TrainingRecap";

const RecapSlide = ({ recapData, useKilometers }) => {
  const theme = useTheme();
  const TrophySvgUrl = getSvgUrl("trophy");
  const FlagSvgUrl = getSvgUrl("flag");
  const MeterSvgUrl = getSvgUrl("meter");
  const RunningSvgUrl = getSvgUrl("running");

  const getTimeInvestedMessage = useCallback((totalSeconds) => {
    const days = totalSeconds / (24 * 60 * 60);
    if (days >= 2) {
      return `That's over ${Math.floor(days)} days of running!`;
    } else if (days >= 1) {
      return `That's over a full day of running!`;
    } else {
      return `That's almost an entire day of running!`;
    }
  }, []);

  const getRunningDistanceMessage = useCallback(
    (distanceStr, useKilometers) => {
      const distanceMatch = distanceStr.match(/^(\d+\.?\d*)\s*(mi|km)$/);

      if (!distanceMatch) {
        return "Invalid distance format!";
      }

      const distance = parseFloat(distanceMatch[1]);
      const unit = distanceMatch[2];

      const distanceComparisons = [
        {
          miles: 50,
          kilometers: 80.4672,
          location: "San Francisco to San Jose",
        },
        {
          miles: 100,
          kilometers: 160.934,
          location: "Los Angeles to Santa Barbara",
        },
        { miles: 150, kilometers: 241.402, location: "London to Birmingham" },
        {
          miles: 200,
          kilometers: 321.869,
          location: "New York City to Washington, D.C.",
        },
        { miles: 250, kilometers: 402.336, location: "Paris to Brussels" },
        { miles: 300, kilometers: 482.803, location: "Tokyo to Nagoya" },
        { miles: 350, kilometers: 563.27, location: "Sydney to Canberra" },
        { miles: 400, kilometers: 643.738, location: "Chicago to St. Louis" },
        { miles: 450, kilometers: 724.205, location: "Rome to Milan" },
        { miles: 500, kilometers: 804.672, location: "London to Edinburgh" },
        {
          miles: 550,
          kilometers: 885.139,
          location: "Seattle to Vancouver, B.C.",
        },
        { miles: 600, kilometers: 965.606, location: "Madrid to Barcelona" },
        { miles: 650, kilometers: 1046.07, location: "Boston to Cleveland" },
        { miles: 700, kilometers: 1126.54, location: "Houston to New Orleans" },
        { miles: 750, kilometers: 1207.01, location: "Beijing to Shanghai" },
        { miles: 800, kilometers: 1287.47, location: "Miami to Atlanta" },
        { miles: 850, kilometers: 1367.94, location: "Berlin to Vienna" },
        {
          miles: 900,
          kilometers: 1448.41,
          location: "Los Angeles to San Francisco and back",
        },
        { miles: 950, kilometers: 1528.87, location: "Toronto to Quebec City" },
        {
          miles: 1000,
          kilometers: 1609.34,
          location: "New York City to Chicago",
        },
        { miles: 1050, kilometers: 1690.81, location: "Paris to Berlin" },
        {
          miles: 1100,
          kilometers: 1772.27,
          location: "San Francisco to Denver",
        },
        { miles: 1150, kilometers: 1853.74, location: "Los Angeles to Dallas" },
        { miles: 1200, kilometers: 1935.21, location: "London to Rome" },
        { miles: 1250, kilometers: 2016.67, location: "Tokyo to Seoul" },
        {
          miles: 1300,
          kilometers: 2098.14,
          location: "Sydney to Melbourne and back",
        },
        {
          miles: 1350,
          kilometers: 2179.61,
          location: "New York City to Miami",
        },
        { miles: 1400, kilometers: 2261.07, location: "Beijing to Tokyo" },
        { miles: 1450, kilometers: 2342.54, location: "Boston to Houston" },
        {
          miles: 1500,
          kilometers: 2424.01,
          location: "Los Angeles to Seattle",
        },
        { miles: 1550, kilometers: 2505.47, location: "Paris to Istanbul" },
        { miles: 1600, kilometers: 2586.94, location: "Chicago to Las Vegas" },
        { miles: 1650, kilometers: 2668.41, location: "Berlin to Moscow" },
        { miles: 1700, kilometers: 2749.87, location: "Rome to Cairo" },
        {
          miles: 1750,
          kilometers: 2831.34,
          location: "New York City to Dallas",
        },
        { miles: 1800, kilometers: 2912.81, location: "Toronto to Miami" },
        { miles: 1850, kilometers: 2994.27, location: "Sydney to Auckland" },
        {
          miles: 1900,
          kilometers: 3075.74,
          location: "Los Angeles to Chicago",
        },
        { miles: 1950, kilometers: 3157.21, location: "London to Athens" },
        {
          miles: 2000,
          kilometers: 3238.67,
          location: "New York City to Mexico City",
        },
      ];

      const comparison = useKilometers
        ? distanceComparisons.find((c) => distance <= c.kilometers)
        : distanceComparisons.find((c) => distance <= c.miles);

      if (comparison) {
        const { location } = comparison;
        return `${distance} ${unit} is the distance from ${location}!`;
      } else {
        return `You've covered over ${
          useKilometers ? "3238 km" : "2000 miles"
        }! That's incredible!`;
      }
    },
    []
  );

  const SlideContent = ({
    title,
    value,
    subtitle,
    iconUrl,
    additionalText,
  }) => (
    <Box
      sx={{
        position: "relative",
        width: "100%",
        flex: 1,
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        padding: "25px",
        marginBottom: "20px",
      }}
    >
      <Typography variant="h4" color="primary" gutterBottom>
        {title}
      </Typography>
      <Box
        sx={{
          position: "relative",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Box
          component="img"
          src={iconUrl}
          sx={{
            width: 80,
            height: 80,
            opacity: 0.2,
            position: "absolute",
          }}
        />
        <motion.div
          initial={{ scale: 0 }}
          animate={{ scale: 1 }}
          transition={{ type: "spring", stiffness: 260, damping: 20 }}
        >
          <Typography
            variant="h2"
            sx={{
              fontWeight: "bold",
              color: theme.palette.text.primary,
            }}
          >
            {value}
          </Typography>
        </motion.div>
      </Box>
      <Typography variant="subtitle1" color="text.secondary">
        {subtitle}
      </Typography>
      {additionalText && (
        <Typography
          variant="body2"
          color="text.secondary"
          sx={{ mt: 1, fontStyle: "italic" }}
        >
          {additionalText}
        </Typography>
      )}
    </Box>
  );

  return (
    <Box sx={{ height: "100%", display: "flex", flexDirection: "column" }}>
      <Stack
        spacing={2}
        sx={{
          flex: 1,
          justifyContent: "space-between",
          p: 2,
          overflowY: "auto",
          "&::-webkit-scrollbar": { display: "none" },
          scrollbarWidth: "none",
          paddingBottom: "80px",
        }}
      >
        <SlideContent
          title="You've Come So Far!"
          value={recapData.summary_stats.total_runs}
          subtitle="Total Runs"
          iconUrl={TrophySvgUrl}
        />
        <SlideContent
          title="Distance Covered"
          value={formatDistance(
            convertDistance(
              recapData.summary_stats.total_distance,
              useKilometers
            ),
            useKilometers
          )}
          subtitle="Total Distance"
          iconUrl={RunningSvgUrl}
          additionalText={getRunningDistanceMessage(
            formatDistance(
              convertDistance(
                recapData.summary_stats.total_distance,
                useKilometers
              ),
              useKilometers
            ),
            useKilometers
          )}
        />
        <SlideContent
          title="Time Invested"
          value={`${Math.floor(
            recapData.summary_stats.total_time / 86400
          )}d ${Math.floor(
            (recapData.summary_stats.total_time % 86400) / 3600
          )}h`}
          subtitle="Total Time Running"
          iconUrl={MeterSvgUrl}
          additionalText={getTimeInvestedMessage(
            recapData.summary_stats.total_time
          )}
        />
        <SlideContent
          title="Your Dedication"
          value={formatDistance(
            convertDistance(
              recapData.summary_stats.average_weekly_distance,
              useKilometers
            ),
            useKilometers
          )}
          subtitle="Average Weekly Distance"
          iconUrl={FlagSvgUrl}
        />
      </Stack>
      {/* Clear area for controls */}
      <Box sx={{ height: 100 }} />
    </Box>
  );
};

export default RecapSlide;
