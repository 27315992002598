import React, { useEffect, useState, useMemo } from "react";
import { motion, AnimatePresence } from "framer-motion";
import { Box, Typography, Avatar } from "@mui/material";
import { useTrainingRecap } from "../../../contexts/TrainingRecapContext";
import { getSvgUrl } from "../../../utils";
import HeartSVG from "../../../strava_icons_svg/heart_rate.svg";
import KudosSVG from "../../../strava_icons_svg/kudos.svg";
import { useTheme } from "@mui/material/styles";

const KudosSlide = ({ isVisible }) => {
  const theme = useTheme();
  const { recapData } = useTrainingRecap();
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);
  const [screenHeight, setScreenHeight] = useState(window.innerHeight);
  const KudosSVGUrl = getSvgUrl("kudos");
  const stravaOrange = process.env.REACT_APP_STRAVA_ORANGE;
  const stravaLightGrey = process.env.REACT_APP_STRAVA_LIGHT_GREY;
  const [kudosCount, setKudosCount] = useState(0);
  // const HeartSvgUrl = getSvgUrl(HeartSVG);

  useEffect(() => {
    if (isVisible) {
      const interval = setInterval(() => {
        setKudosCount((prev) =>
          Math.min(
            prev + Math.floor(Math.random() * 10),
            recapData.kudos_info.total_kudos
          )
        );
      }, 10);
      return () => clearInterval(interval);
    }
  }, [isVisible, recapData.kudos_info.total_kudos]);

  useEffect(() => {
    const handleResize = () => {
      setScreenWidth(window.innerWidth);
      setScreenHeight(window.innerHeight);
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const thumbsUpPositions = useMemo(() => {
    return Array.from({ length: 100 }, () => ({
      x: Math.random() * screenWidth,
      y: Math.random() * screenHeight,
      size: Math.random() * 150 + 20,
      delay: Math.random() * 2, // Increased delay range
    }));
  }, [screenWidth, screenHeight]);

  return (
    <Box
      sx={{
        width: "100%",
        height: "100%",
        overflow: "hidden",
        position: "relative",
        padding: "25px",
        // bgcolor: theme.palette.background.default,
      }}
    >
      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 1 }}
        style={{
          position: "relative",
          overflow: "hidden",
          width: "100%",
          height: "100%",
          padding: 0, // Ensure no padding
          margin: 0, // Ensure no margin
        }}
      >
        {/* Background Pattern */}
        <Box
          style={{
            position: "absolute",
            top: "-50%",
            left: "-50%",
            width: "500%",
            height: "500%",
            backgroundImage: KudosSVGUrl,
            backgroundSize: "30px 30px",
            backgroundRepeat: "repeat",
            opacity: 0.1,
            filter: stravaLightGrey,
            transform: "rotate(-45deg)",
            zIndex: 0, // Make sure it is behind the content
          }}
        />
        <Box
          sx={{
            position: "relative",
            zIndex: 1,
            textAlign: "center",
          }}
        >
          <Box>
            <Typography variant="h2" gutterBottom color="primary">
              Kudos to You!
            </Typography>
            <motion.div
              initial={{ scale: 0 }}
              animate={{ scale: 1 }}
              transition={{ type: "spring", stiffness: 260, damping: 20 }}
            >
              <Typography variant="h1" gutterBottom>
                {kudosCount.toLocaleString()}
              </Typography>
            </motion.div>
            <Typography variant="h4" gutterBottom>
              Total Kudos
            </Typography>
          </Box>
          {/* <div style={{ position: "relative", zIndex: 1, padding: "20px" }}> */}
          <Box sx={{ mt: 3, mb: 2 }}>
            <Typography variant="h4">Your Training in Kudos</Typography>
            <Typography variant="h5">
              Average Kudos per Activity{" "}
              {recapData.kudos_info.average_kudos_per_activity.toFixed(2)}
            </Typography>
            {/* <Typography variant="h4" gutterBottom> */}
            <Typography variant="h6" sx={{ mt: 3, mb: 1 }}>
              Given by your top supporters:
            </Typography>
            <Box
              sx={{
                mb: 2,
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              {recapData.kudos_info.top_kudoers
                .slice(0, 10)
                .map((kudoer, index) => (
                  <Box
                    key={index}
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      mt: 1,
                      width: "100%",
                    }}
                  >
                    <Avatar
                      sx={{
                        bgcolor: "background.paper",
                        mr: 2,
                        border: `1px solid ${stravaOrange}`,
                      }}
                    >
                      <Box
                        component="img"
                        src={HeartSVG}
                        sx={{ width: 24, height: 24, filter: stravaOrange }}
                      />
                    </Avatar>
                    <Typography variant="h5" sx={{ ml: 2 }}>
                      {kudoer.name} - {kudoer.count} Kudos
                    </Typography>
                  </Box>
                ))}
            </Box>
          </Box>
        </Box>
        <AnimatePresence>
          {/* Render the thumbs-up SVGs */}
          {isVisible &&
            thumbsUpPositions.map((pos, index) => (
              <motion.img
                key={index}
                src={KudosSVG}
                alt="Thumbs Up"
                style={{
                  position: "absolute",
                  width: `${pos.size}px`,
                  height: `${pos.size}px`,
                  top: `${pos.y}px`,
                  left: `${pos.x}px`,
                  filter: stravaOrange,
                }}
                initial={{ opacity: 0, scale: 0 }}
                animate={{ opacity: 1, scale: 1 }}
                exit={{ opacity: 0, scale: 0 }}
                transition={{
                  delay: pos.delay,
                  duration: 1,
                  repeat: 1,
                  repeatType: "reverse",
                }}
              />
            ))}
        </AnimatePresence>
      </motion.div>
    </Box>
  );
};

export default KudosSlide;
