// import React from "react";
// import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
// import { ThemeProvider } from "@mui/material/styles";
// import CssBaseline from "@mui/material/CssBaseline";
// import Container from "@mui/material/Container";
// import Box from "@mui/material/Box";
// import { useTheme } from "@mui/material/styles";
// import stravaTheme from "./theme";
// import Athlete from "./components/Athlete";
// import StravaCallback from "./components/StravaCallback";
// import Home from "./components/Home";
// import StravaRedirect from "./components/StravaRedirect";
// import Navigation from "./components/Navigation";
// import TrainingRecapContainer from "./components/TrainingRecap/TrainingRecapContainer";
// import { AnalysisProvider } from "./contexts/AnalysisContext";
// import { TrainingRecapProvider } from "./contexts/TrainingRecapContext";
// import { AthleteProvider } from "./contexts/AthleteContext";
// import { GatheringDataProvider } from "./contexts/GatheringDataContext";
// import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
// import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
// import "leaflet/dist/leaflet.css";

import React from "react";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  useLocation,
} from "react-router-dom";
import { ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import Container from "@mui/material/Container";
import Box from "@mui/material/Box";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
// import theme from "./theme";
import stravaTheme from "./theme";
import Athlete from "./components/Athlete";
import Navigation from "./components/Navigation";
import StravaCallback from "./components/StravaCallback";
import Home from "./components/Home";
import StravaRedirect from "./components/StravaRedirect";
import TrainingRecapContainer from "./components/TrainingRecap/TrainingRecapContainer";
import { AnalysisProvider } from "./contexts/AnalysisContext";
import { TrainingRecapProvider } from "./contexts/TrainingRecapContext";
import { AthleteProvider } from "./contexts/AthleteContext";
import { GatheringDataProvider } from "./contexts/GatheringDataContext";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import "leaflet/dist/leaflet.css";
import { blue } from "@mui/material/colors";

function App() {
  // Force the layout to behave as mobile
  const isMobile = true; // This forces all devices to behave as mobile

  return (
    <ThemeProvider theme={stravaTheme}>
      <CssBaseline />
      <AthleteProvider>
        <GatheringDataProvider>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <AnalysisProvider>
              <Router>
                <Box
                  display="flex"
                  flexDirection="column"
                  minHeight="100vh"
                  sx={{ backgroundColor: "#F7F7FA" }}
                >
                  {/* <Navigation /> */}
                  <Container
                    component="main"
                    sx={{
                      flexGrow: 1,
                      py: 2,
                      mb: isMobile ? 7 : 0, // Always applies mobile bottom margin
                      backgroundColor: "#F7F7FA",
                    }}
                  >
                    <Routes>
                      <Route path="/" element={<Home />} />
                      <Route path="/athlete" element={<Athlete />} />
                      <Route
                        path="/training-recap"
                        element={
                          <TrainingRecapProvider>
                            <TrainingRecapContainer />
                          </TrainingRecapProvider>
                        }
                      />
                      <Route
                        path="/strava/callback"
                        element={<StravaCallback />}
                      />
                      <Route
                        path="/strava-redirect"
                        element={<StravaRedirect />}
                      />
                    </Routes>
                  </Container>
                  <ResponsiveNavigation isMobile={isMobile} />
                </Box>
              </Router>
            </AnalysisProvider>
          </LocalizationProvider>
        </GatheringDataProvider>
      </AthleteProvider>
    </ThemeProvider>
  );
}

// Updated ResponsiveNavigation function to use forced mobile layout
// function ResponsiveNavigation({ isMobile }) {
//   if (isMobile) {
//     return <Navigation />;
//   }
//   return null;
// }

function ResponsiveNavigation({ isMobile }) {
  const location = useLocation();

  if (isMobile && location.pathname !== "/") {
    return <Navigation />;
  }
  return null;
}

export default App;

// import React from "react";
// import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
// import { ThemeProvider } from "@mui/material/styles";
// import CssBaseline from "@mui/material/CssBaseline";
// import Container from "@mui/material/Container";
// import Box from "@mui/material/Box";
// import useMediaQuery from "@mui/material/useMediaQuery";
// import { useTheme } from "@mui/material/styles";
// // import theme from "./theme";
// import stravaTheme from "./theme";
// import Athlete from "./components/Athlete";
// import Activities from "./components/Activities";
// import ActivityDetail from "./components/ActivityDetail";
// import ActivityBase from "./components/ActivityBase";
// import StravaAnalytics from "./components/StravaAnalytics";
// import Dashboard from "./components/Dashboard";
// import Navigation from "./components/Navigation";
// import AIInsights from "./components/AIInsights";
// import StravaCallback from "./components/StravaCallback";
// import Home from "./components/Home";
// import StravaRedirect from "./components/StravaRedirect";
// import TrainingRecap from "./components/TrainingRecap/TrainingRecap";
// import TrainingRecapContainer from "./components/TrainingRecap/TrainingRecapContainer";
// import { AnalysisProvider } from "./contexts/AnalysisContext";
// import { TrainingRecapProvider } from "./contexts/TrainingRecapContext";
// import { AthleteProvider } from "./contexts/AthleteContext";
// import { GatheringDataProvider } from "./contexts/GatheringDataContext";
// import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
// import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
// import "leaflet/dist/leaflet.css";
// import { blue } from "@mui/material/colors";

// function App() {
//   // const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
//   // const isMobile = useMediaQuery(stravaTheme.breakpoints.down("sm"));
//   const isMobile = true;
//   const theme = useTheme();

//   return (
//     <ThemeProvider theme={stravaTheme}>
//       <CssBaseline />
//       <AthleteProvider>
//         <GatheringDataProvider>
//           <LocalizationProvider dateAdapter={AdapterDayjs}>
//             <AnalysisProvider>
//               <Router>
//                 <Box
//                   display="flex"
//                   flexDirection="column"
//                   minHeight="100vh"
//                   sx={{ backgroundColor: "#F7F7FA" }}
//                 >
//                   <Navigation />
//                   <Container
//                     component="main"
//                     sx={{
//                       flexGrow: 1,
//                       py: 2,
//                       mb: isMobile ? 7 : 0,
//                       backgroundColor: "#F7F7FA",
//                     }}
//                   >
//                     <Routes>
//                       <Route path="/" element={<Home />} />
//                       <Route path="/athlete" element={<Athlete />} />
//                       <Route
//                         path="/training-recap"
//                         element={
//                           <TrainingRecapProvider>
//                             <TrainingRecapContainer />
//                           </TrainingRecapProvider>
//                         }
//                       />
//                       <Route
//                         path="/strava/callback"
//                         element={<StravaCallback />}
//                       />
//                       <Route
//                         path="/strava-redirect"
//                         element={<StravaRedirect />}
//                       />
//                     </Routes>
//                   </Container>
//                   <ResponsiveNavigation />
//                 </Box>
//               </Router>
//             </AnalysisProvider>
//           </LocalizationProvider>
//         </GatheringDataProvider>
//       </AthleteProvider>
//     </ThemeProvider>
//   );
// }

// function ResponsiveNavigation() {
//   const theme = useTheme();
//   // const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
//   const isMobile = true;

//   if (isMobile) {
//     return <Navigation />;
//   }

//   return null;
// }

// export default App;
