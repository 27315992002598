import React, { useState, useEffect } from "react";
import {
  Container,
  Typography,
  Grid,
  Paper,
  CircularProgress,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Button,
  Box,
} from "@mui/material";
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
  PieChart,
  Pie,
  Cell,
} from "recharts";

import TrainingRecapVideo from "./TrainingRecapVideo";
import { useTrainingRecap } from "../../contexts/TrainingRecapContext";
import { useTheme, useMediaQuery } from "@mui/material";

const TrainingRecap = () => {
  const { loading, useKilometers, toggleUnits, recapData } = useTrainingRecap();
  const [showVideo, setShowVideo] = useState(false);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  if (!recapData) {
    return <Typography>No recap data available.</Typography>;
  }

  if (loading) {
    return <CircularProgress />;
  }

  return (
    <Container maxWidth={isMobile ? "xs" : "lg"}>
      {recapData ? (
        <>
          <Button
            variant="contained"
            color="primary"
            onClick={() => setShowVideo(true)}
            // sx={{ mb: 2, width: isMobile ? "100%" : "auto" }}
            sx={{
              mb: 2,
              width: isMobile ? "100%" : "auto",
              padding: "8px 16px",
              textTransform: "uppercase",
              fontWeight: theme.typography.fontWeightMedium,
              boxShadow: "none",
              "&:hover": {
                boxShadow: "none",
              },
            }}
          >
            View Recap Video
          </Button>
          {/* <Grid container spacing={{ xs: 1, sm: 2, md: 3 }}> */}
          <Grid container spacing={2}>
            <Grid item xs={12} md={12}>
              <SummaryStatistics
                isMobile
                stats={recapData.summary_stats}
                useKilometers={useKilometers}
                toggleUnits={toggleUnits}
              />
            </Grid>
            <Grid item xs={12} md={12}>
              <PerformanceInsights
                isMobile
                insights={recapData.performance_insights}
                useKilometers={useKilometers}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <HeartRateZones data={recapData.heart_rate_data} isMobile />
            </Grid>
            <Grid item xs={12} md={6}>
              <HeartRateStats data={recapData.heart_rate_data} isMobile />
            </Grid>
            <Grid item xs={12} md={12}>
              <WeeklyMileageChart
                isMobile
                data={recapData.weekly_data}
                useKilometers={useKilometers}
              />
            </Grid>
            <Grid item xs={12} md={12}>
              <LongRunProgressionChart
                isMobile
                data={recapData.long_runs}
                useKilometers={useKilometers}
              />
            </Grid>
            <Grid item xs={12} md={12}>
              <PaceProgressionChart
                isMobile
                data={recapData.pace_data}
                useKilometers={useKilometers}
              />
            </Grid>
          </Grid>
          {showVideo && (
            <TrainingRecapVideo onClose={() => setShowVideo(false)} />
          )}
        </>
      ) : (
        <Typography variant={isMobile ? "body2" : "body1"}>
          Please apply filters to view training recap data.
        </Typography>
      )}
    </Container>
  );
};

export const convertDistance = (meters, useKilometers) => {
  return useKilometers ? meters / 1000 : meters / 1609.34;
};

export const formatDistance = (distance, useKilometers) => {
  return `${distance.toFixed(2)} ${useKilometers ? "km" : "mi"}`;
};

export const formatTime = (seconds) => {
  const days = Math.floor(seconds / 86400);
  const hours = Math.floor((seconds % 86400) / 3600);
  const minutes = Math.floor((seconds % 3600) / 60);
  const remainingSeconds = seconds % 60;

  // Conditionally include days if the total time is greater than 1 day
  let formattedTime = "";
  if (days > 0) {
    formattedTime += `${days}d `;
  }
  formattedTime += `${hours}h ${minutes}m ${remainingSeconds.toFixed(2)}s`;

  return formattedTime;
};

export const formatPace = (secondsPerKm, useKilometers) => {
  const paceInMinutes = secondsPerKm / 60;
  const paceDistance = useKilometers ? "km" : "mi";
  const adjustedPace = useKilometers ? paceInMinutes : paceInMinutes * 1.60934;
  const minutes = Math.floor(adjustedPace);
  const seconds = Math.round((adjustedPace - minutes) * 60);
  return `${minutes}:${seconds
    .toString()
    .padStart(2, "0")} min/${paceDistance}`;
};

// Sub-components
export const SummaryStatistics = ({
  stats,
  useKilometers,
  toggleUnits,
  isMobile,
}) => {
  const theme = useTheme();

  return (
    <Box
      sx={{
        backgroundColor: theme.palette.background.paper,
        mb: 2,
        mt: 2,
        borderRadius: 1,
      }}
    >
      <Typography variant="h6" fontWeight="bold" gutterBottom>
        Training Summary Statistics
      </Typography>
      <Button
        onClick={toggleUnits}
        variant="contained"
        color="primary"
        sx={{
          mb: 2,
          textTransform: "uppercase",
          fontWeight: "bold",
          py: 0.5,
          px: 1,
        }}
      >
        Use: {useKilometers ? "Miles" : "Kilometers"}
      </Button>
      <Table size="small">
        <TableBody>
          {Object.entries(stats).map(([key, value]) => (
            <TableRow
              key={key}
              sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
            >
              <TableCell
                component="th"
                scope="row"
                sx={{
                  borderBottom: "none",
                  py: 1,
                  pl: 0,
                  color: theme.palette.text.secondary,
                  fontSize: "0.875rem",
                }}
              >
                {key
                  .replace(/_/g, " ")
                  .replace(/\b\w/g, (l) => l.toUpperCase())}
              </TableCell>
              <TableCell
                align="right"
                sx={{
                  borderBottom: "none",
                  py: 1,
                  pr: 0,
                  fontWeight: "bold",
                  fontSize: "0.875rem",
                }}
              >
                {key.includes("distance")
                  ? formatDistance(
                      convertDistance(value, useKilometers),
                      useKilometers
                    )
                  : key.includes("time")
                  ? formatTime(value)
                  : key.includes("pace")
                  ? formatPace(value, useKilometers)
                  : value.toFixed(2)}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </Box>
  );
};

export const WeeklyMileageChart = ({ data, useKilometers, isMobile }) => {
  const chartData = Object.entries(data).map(([date, values]) => ({
    week: new Date(date).toLocaleDateString(),
    distance: convertDistance(values.distance, useKilometers),
  }));

  const theme = useTheme();

  return (
    <Box
      sx={{
        backgroundColor: theme.palette.background.paper,
        mb: 2,
        mt: 2,
        borderRadius: 1,
      }}
    >
      <Typography variant="h6" fontWeight="bold" gutterBottom>
        Weekly Distance
      </Typography>
      <ResponsiveContainer width="100%" height={isMobile ? 200 : "100%"}>
        <LineChart data={chartData}>
          <CartesianGrid
            strokeDasharray="3 3"
            stroke={theme.palette.grey[300]}
          />
          <XAxis
            dataKey="week"
            tick={{ fill: theme.palette.text.primary, fontSize: 12 }}
          />
          <YAxis tick={{ fill: theme.palette.text.primary, fontSize: 12 }} />
          <Tooltip
            formatter={(value) => formatDistance(value, useKilometers)}
            contentStyle={{
              backgroundColor: theme.palette.background.paper,
              border: `1px solid ${theme.palette.divider}`,
              borderRadius: theme.shape.borderRadius,
            }}
          />
          <Legend />
          <Line
            type="monotone"
            dataKey="distance"
            stroke={theme.palette.primary.main} // Adjust color based on metric
            strokeWidth={2}
            name={`Distance (${useKilometers ? "km" : "mi"})`}
            dot={{ r: 3, fill: theme.palette.primary.main }} // Smaller dots with primary color
            activeDot={{ r: 6, fill: theme.palette.complementary.main }} // Larger, active dots
          />
        </LineChart>
      </ResponsiveContainer>
    </Box>
  );
};

export const LongRunProgressionChart = ({ data, useKilometers, isMobile }) => {
  const chartData = data.map((run) => ({
    date: new Date(run.start_date).toLocaleDateString(),
    distance: convertDistance(run.distance, useKilometers),
  }));

  const theme = useTheme();

  return (
    <Box
      sx={{
        backgroundColor: theme.palette.background.paper,
        mb: 2,
        mt: 2,
        borderRadius: 1,
      }}
    >
      <Typography variant="h6" fontWeight="bold" gutterBottom>
        Long Run Progression
      </Typography>
      {/* <Box sx={{ flexGrow: 1 }}> */}
      <ResponsiveContainer width="100%" height={isMobile ? 200 : "100%"}>
        <LineChart data={chartData}>
          <CartesianGrid
            strokeDasharray="3 3"
            stroke={theme.palette.grey[300]}
          />
          <XAxis
            dataKey="date"
            tick={{ fill: theme.palette.text.primary, fontSize: 12 }}
          />
          <YAxis tick={{ fill: theme.palette.text.primary, fontSize: 12 }} />
          <Tooltip
            formatter={(value) => formatDistance(value, useKilometers)}
            contentStyle={{
              backgroundColor: theme.palette.background.paper,
              border: `1px solid ${theme.palette.divider}`,
              borderRadius: theme.shape.borderRadius,
            }}
          />
          <Legend />
          <Line
            type="monotone"
            dataKey="distance"
            stroke={theme.palette.tertiary.main} // Using success color for this chart
            strokeWidth={2}
            name={`Distance (${useKilometers ? "km" : "mi"})`}
            dot={{ r: 3, fill: theme.palette.tertiary.main }} // Smaller dots with success color
            activeDot={{ r: 6, fill: theme.palette.coolCyan.main }} //
          />
        </LineChart>
      </ResponsiveContainer>
    </Box>
    // {/* </Paper> */}
    // </Box>
  );
};

export const formatPaceYAxis = (secondsPerKm, useKilometers) => {
  const paceInMinutes = secondsPerKm / 60;
  const adjustedPace = useKilometers ? paceInMinutes : paceInMinutes * 1.60934;
  const seconds = Math.round(adjustedPace * 60);
  return seconds;
};

export const formatPaceTip = (secondsPerKm, useKilometers) => {
  const paceInMinutes = secondsPerKm / 60;
  const minutes = Math.floor(paceInMinutes);
  const seconds = Math.round((paceInMinutes - minutes) * 60);
  return `${minutes}:${seconds.toString().padStart(2, "0")} min/${
    useKilometers ? "km" : "mi"
  }`;
};

export const PaceProgressionChart = ({ data, useKilometers, isMobile }) => {
  const theme = useTheme();
  const chartData = data.map((run) => {
    const pace = formatPaceYAxis(run.pace, useKilometers);

    return {
      date: new Date(run.date).toLocaleDateString(),
      pace: pace,
    };
  });

  const formatXAxis = (tickItem) => {
    const date = new Date(tickItem);
    return `${date.getMonth() + 1}/${date.getDate()}`;
  };

  const formatYAxis = (tickItem) => {
    const minutes = Math.floor(tickItem / 60);
    const seconds = Math.round(tickItem % 60);
    return `${minutes}:${seconds.toString().padStart(2, "0")}`;
  };

  return (
    <Box
      sx={{
        backgroundColor: theme.palette.background.paper,
        // backgroundColor: theme.palette.background.default,
        mb: 2,
        mt: 2,
        borderRadius: 1,
      }}
    >
      <Typography variant="h6" fontWeight="bold" gutterBottom>
        Pace Progression
      </Typography>
      <ResponsiveContainer width="100%" height={isMobile ? 200 : "100%"}>
        <LineChart data={chartData}>
          <CartesianGrid
            strokeDasharray="3 3"
            stroke={theme.palette.grey[300]}
          />
          <XAxis
            dataKey="date"
            tickFormatter={formatXAxis}
            minTickGap={isMobile ? 10 : 20}
            interval={"preserveStartEnd"}
            tick={{ fill: theme.palette.text.primary, fontSize: 12 }}
          />
          <YAxis
            tickFormatter={formatYAxis}
            tick={{ fill: theme.palette.text.primary, fontSize: 12 }}
          />
          <Tooltip
            formatter={(value) => formatPaceTip(value, useKilometers)}
            contentStyle={{
              backgroundColor: theme.palette.background.paper,
              border: `1px solid ${theme.palette.divider}`,
              borderRadius: theme.shape.borderRadius,
            }}
          />
          <Legend />
          <Line
            type="monotone"
            dataKey="pace"
            stroke={theme.palette.warning.main} // Using warning color for pace chart line
            strokeWidth={2}
            name={`Pace (min/${useKilometers ? "km" : "mi"})`}
            dot={{ r: 3, fill: theme.palette.warning.main }} // Smaller dots with warning color
            activeDot={{ r: 6, fill: theme.palette.accentTeal.main }}
          />
        </LineChart>
      </ResponsiveContainer>
      {/* </Paper> */}
    </Box>
  );
};

export const PerformanceInsights = ({ insights, useKilometers, isMobile }) => {
  const theme = useTheme();
  const formatPace = (seconds, unit) => {
    if (unit === "seconds_per_km") {
      const paceInMinutes = seconds / 60;
      const adjustedPace = useKilometers
        ? paceInMinutes
        : paceInMinutes * 1.60934;
      const minutes = Math.floor(adjustedPace);
      const remainingSeconds = Math.round((adjustedPace - minutes) * 60);
      return `${minutes}:${remainingSeconds.toString().padStart(2, "0")} min/${
        useKilometers ? "km" : "mi"
      }`;
    }
    return `${seconds.toFixed(2)} ${unit}`;
  };

  const formatInsight = (insight) => {
    let formattedValue = formatPace(insight.value, insight.unit);
    let message = insight.message;

    if (insight.type === "improvement" || insight.type === "slowdown") {
      formattedValue = formatPace(Math.abs(insight.value), insight.unit);
      message = `${message} You're ${formattedValue} ${insight.comparison} than your overall average.`;
    }

    return (
      <Typography
        key={insight.type}
        variant="body2"
        sx={{ mb: 1, color: theme.palette.text.secondary }}
      >
        • {message} ({formattedValue})
        {insight.date && ` on ${new Date(insight.date).toLocaleDateString()}`}
      </Typography>
    );
  };

  return (
    <Box
      sx={{
        backgroundColor: theme.palette.background.paper,
        // p: 2,
        borderRadius: 1,
        mb: 2,
        mt: 2,
      }}
    >
      <Typography variant="h6" fontWeight="bold" gutterBottom>
        Performance Insights
      </Typography>
      {insights.map(formatInsight)}
    </Box>
  );
};

export const HeartRateZones = ({ data, isMobile }) => {
  const theme = useTheme();
  if (!data) {
    return (
      <Typography variant="body2">No heart rate data available</Typography>
    );
  }

  // Using theme colors for the heart rate zones
  const COLORS = [
    theme.palette.grey[400], // Zone 1 (Recovery)
    theme.palette.primary.light, // Zone 2 (Aerobic)
    theme.palette.primary.main, // Zone 3 (Tempo)
    theme.palette.warning.main, // Zone 4 (Threshold)
    theme.palette.error.main, // Zone 5 (Anaerobic)
  ];

  const totalTime = data.zones.reduce(
    (sum, zone) => sum + zone.time_in_zone,
    0
  );
  const pieData = data.zones.map((zone) => ({
    name: zone.zone,
    value: zone.time_in_zone,
    percentage: ((zone.time_in_zone / totalTime) * 100).toFixed(1),
  }));

  const maxPercentageZone = pieData.reduce(
    (max, zone) =>
      parseFloat(zone.percentage) > parseFloat(max.percentage) ? zone : max,
    pieData[0]
  );

  const renderCustomLegend = () => (
    <Box
      sx={{
        display: "flex",
        justifyContent: "space-between",
        width: "100%",
        mb: 2,
        mt: 2,
      }}
    >
      {data.zones.map((_, index) => (
        <Box key={index} sx={{ display: "flex", alignItems: "center" }}>
          <Box
            sx={{
              width: 8,
              height: 8,
              backgroundColor: COLORS[index],
              mr: 0.5,
              borderRadius: "50%",
            }}
          />
          <Typography variant="caption" color="text.secondary">{`Zone ${
            index + 1
          }`}</Typography>
        </Box>
      ))}
    </Box>
  );

  return (
    <Box
      sx={{
        backgroundColor: theme.palette.background.paper,
        // backgroundColor: theme.palette.background.default,
        // p: 2,
        mb: 2,
        mt: 2,
        borderRadius: 1,
        height: "100%",
      }}
    >
      <Typography variant="h6" fontWeight="bold" gutterBottom>
        Heart Rate Zones
      </Typography>
      <Box sx={{ height: 200, position: "relative" }}>
        <ResponsiveContainer width="100%" height="100%">
          <PieChart>
            <Pie
              data={pieData}
              dataKey="value"
              nameKey="name"
              cx="50%"
              cy="50%"
              innerRadius="70%"
              outerRadius="100%"
              strokeWidth={0}
            >
              {pieData.map((entry, index) => (
                <Cell
                  key={`cell-${index}`}
                  fill={COLORS[index % COLORS.length]}
                />
              ))}
            </Pie>
          </PieChart>
        </ResponsiveContainer>
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            textAlign: "center",
          }}
        >
          <Typography variant="h4" fontWeight="bold">
            {maxPercentageZone.percentage}%
          </Typography>
          <Typography variant="body2" color="text.secondary">
            {/* {maxPercentageZone.name.split(" ")[0:1]} */}
            {maxPercentageZone.name.split(" ").slice(0, 2).join(" ")}
          </Typography>
        </Box>
      </Box>
      {renderCustomLegend()}
      <Box sx={{ mt: 2, display: "flex", justifyContent: "space-between" }}>
        <Box>
          <Typography variant="body2" color="text.secondary">
            Avg Heart Rate
          </Typography>
          <Typography variant="body1" fontWeight="bold">
            {Math.round(data.average_hr)} bpm
          </Typography>
        </Box>
        <Box>
          <Typography variant="body2" color="text.secondary" align="right">
            Max Heart Rate
          </Typography>
          <Typography variant="body1" fontWeight="bold" align="right">
            {data.max_hr} bpm
          </Typography>
        </Box>
      </Box>
    </Box>
  );
};

export const HeartRateStats = ({ data, isMobile }) => {
  const theme = useTheme();

  if (!data) {
    return (
      <Typography variant="body2">No heart rate data available</Typography>
    );
  }

  const COLORS = [
    theme.palette.grey[400], // Zone 1
    theme.palette.primary.light, // Zone 2
    theme.palette.primary.main, // Zone 3
    theme.palette.warning.main, // Zone 4
    theme.palette.error.main, // Zone 5
  ];

  const totalTime = data.zones.reduce(
    (sum, zone) => sum + zone.time_in_zone,
    0
  );
  return (
    <Box
      sx={{
        backgroundColor: theme.palette.background.paper,
        mb: 2,
        mt: 2,
        borderRadius: 1,
      }}
    >
      <Typography variant="h6" fontWeight="bold" gutterBottom>
        Heart Rate Stats
      </Typography>
      <Box sx={{ mt: 2 }}>
        {data.zones.map((zone, index) => {
          const percentage = (zone.time_in_zone / totalTime) * 100;
          return (
            <Box key={index} sx={{ mb: 2 }}>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  mb: 0.5,
                }}
              >
                <Typography variant="body2" fontWeight="medium">
                  {zone.zone.split(" ")[1]}
                </Typography>
                <Typography variant="body2" fontWeight="medium">
                  {formatTime(zone.time_in_zone)}
                </Typography>
              </Box>
              <Box sx={{ display: "flex", alignItems: "center" }}>
                <Box
                  sx={{
                    flexGrow: 1,
                    height: 4,
                    backgroundColor: theme.palette.grey[300],
                    borderRadius: 2,
                    overflow: "hidden",
                  }}
                >
                  <Box
                    sx={{
                      height: "100%",
                      backgroundColor: COLORS[index],
                      width: `${percentage}%`,
                    }}
                  />
                </Box>
                <Typography
                  variant="caption"
                  color="text.secondary"
                  sx={{ ml: 1, minWidth: 70 }}
                >
                  {zone.min_hr} - {zone.max_hr} bpm
                </Typography>
              </Box>
            </Box>
          );
        })}
      </Box>
    </Box>
  );
};
export default TrainingRecap;
