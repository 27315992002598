import React, { createContext, useContext, useState } from 'react';

const GatheringDataContext = createContext();

export const GatheringDataProvider = ({ children }) => {
  const [gatheringData, setGatheringData] = useState(false);
  
  return (
    <GatheringDataContext.Provider value={{ gatheringData, setGatheringData }}>
      {children}
    </GatheringDataContext.Provider>
  );
};

export const useGatheringData = () => useContext(GatheringDataContext);