import React from "react";
import { Link, useLocation } from "react-router-dom";
import {
  AppBar,
  Toolbar,
  Button,
  Typography,
  BottomNavigation,
  BottomNavigationAction,
  useMediaQuery,
  useTheme,
  Box,
} from "@mui/material";
import HomeIcon from "@mui/icons-material/Home";
import DashboardIcon from "@mui/icons-material/Dashboard";
import DirectionsRunIcon from "@mui/icons-material/DirectionsRun";
import { useGatheringData } from "../contexts/GatheringDataContext";

const Navigation = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const location = useLocation();
  const { gatheringData } = useGatheringData(); // Use the context

  const navigationItems = [
    { label: "Home", path: "/", icon: <HomeIcon sx={{ fontSize: 28 }} /> },
    {
      label: "Training Recap",
      path: "/training-recap",
      icon: <DirectionsRunIcon sx={{ fontSize: 28 }} />,
      disabled: gatheringData,
    },
  ];

  if (isMobile) {
    return (
      <Box
        sx={{ position: "fixed", bottom: 0, left: 0, right: 0, zIndex: 1000 }}
      >
        <BottomNavigation value={location.pathname} showLabels>
          {navigationItems.map((item) => (
            <BottomNavigationAction
              key={item.path}
              label={item.label}
              icon={item.icon}
              component={Link}
              to={item.path}
              value={item.path}
              disabled={item.disabled} // Disable the button if gatheringData is true 
              sx={{
                padding: "6px 12px", // Adjusted padding
                "& .MuiBottomNavigationAction-label": {
                  fontSize: "0.75rem", // Consistent label size
                  fontWeight: theme.typography.fontWeightMedium, // Adjusted label weight
                },
              }}
            />
          ))}
        </BottomNavigation>
      </Box>
    );
  }

  return (
    <AppBar position="static">
      <Toolbar>
        <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
          StravaWrapped
        </Typography>
        {navigationItems.map((item) => (
          <Button
            key={item.path}
            color="inherit"
            component={Link}
            to={item.path}
            startIcon={item.icon}
            disabled={item.disabled} // Disable the button if gatheringData is true
          >
            {item.label}
          </Button>
        ))}
      </Toolbar>
    </AppBar>
  );
};

export default Navigation;
