import React from "react";
import {
  Box,
  Typography,
  Paper,
  Grid,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Button,
  CircularProgress,
} from "@mui/material";
import { ArrowUpCircle, ArrowDownCircle, Lightbulb } from "lucide-react";

import { useTrainingRecap } from "../../contexts/TrainingRecapContext";
import { useTheme, useMediaQuery } from "@mui/material";

const TrainingRecapAnalysis = () => {
  const {
    loadingInsights,
    aiInsight,
    fetchRecapAnalysis,
    errorInsight,
    recapData,
  } = useTrainingRecap();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const handleAnalysisRequest = () => {
    fetchRecapAnalysis(recapData);
  };

  const renderAnalysis = () => {
    if (!aiInsight || !aiInsight.analysis) return null;

    const performanceData = aiInsight.analysis;

    return (
      <Grid container spacing={isMobile ? 2 : 3}>
        <Grid item xs={12}>
          <Paper elevation={isMobile ? 1 : 3} sx={{ p: isMobile ? 2 : 3 }}>
            <Box sx={{ display: "flex", justifyContent: "center" }}>
              <Typography variant={isMobile ? "h6" : "h4"} gutterBottom>
                AI Race Performance Analysis
              </Typography>
            </Box>
            <Typography variant={isMobile ? "subtitle1" : "h6"} gutterBottom>
              Overall Performance
            </Typography>
            <Typography variant={isMobile ? "body2" : "body1"}>
              {performanceData.overall_performance}
            </Typography>
          </Paper>
        </Grid>
        <Grid item xs={12} md={4}>
          <Paper
            elevation={isMobile ? 1 : 3}
            sx={{ p: isMobile ? 2 : 3, height: "100%" }}
          >
            <Typography variant={isMobile ? "subtitle1" : "h6"} gutterBottom>
              Key Strengths
            </Typography>
            <List>
              {performanceData.key_strengths.map((strength, index) => (
                <ListItem key={index}>
                  <ListItemIcon>
                    <ArrowUpCircle color="green" size={isMobile ? 20 : 24} />
                  </ListItemIcon>
                  <ListItemText
                    primary={strength}
                    primaryTypographyProps={{
                      variant: isMobile ? "body2" : "body1",
                    }}
                  />
                </ListItem>
              ))}
            </List>
          </Paper>
        </Grid>
        <Grid item xs={12} md={4}>
          <Paper
            elevation={isMobile ? 1 : 3}
            sx={{ p: isMobile ? 2 : 3, height: "100%" }}
          >
            <Typography variant={isMobile ? "subtitle1" : "h6"} gutterBottom>
              Areas of Improvement
            </Typography>
            <List>
              {performanceData.areas_of_improvement.map((area, index) => (
                <ListItem key={index}>
                  <ListItemIcon>
                    <ArrowDownCircle color="orange" size={isMobile ? 20 : 24} />
                  </ListItemIcon>
                  <ListItemText
                    primary={area}
                    primaryTypographyProps={{
                      variant: isMobile ? "body2" : "body1",
                    }}
                  />
                </ListItem>
              ))}
            </List>
          </Paper>
        </Grid>
        <Grid item xs={12} md={4}>
          <Paper
            elevation={isMobile ? 1 : 3}
            sx={{ p: isMobile ? 2 : 3, height: "100%" }}
          >
            <Typography variant={isMobile ? "subtitle1" : "h6"} gutterBottom>
              Recommendations
            </Typography>
            <List>
              {performanceData.recommendations.map((recommendation, index) => (
                <ListItem key={index}>
                  <ListItemIcon>
                    <Lightbulb color="blue" size={isMobile ? 20 : 24} />
                  </ListItemIcon>
                  <ListItemText
                    primary={recommendation}
                    primaryTypographyProps={{
                      variant: isMobile ? "body2" : "body1",
                    }}
                  />
                </ListItem>
              ))}
            </List>
          </Paper>
        </Grid>
        <Grid item xs={12}>
          <Paper elevation={isMobile ? 1 : 3} sx={{ p: isMobile ? 2 : 3 }}>
            <Typography variant={isMobile ? "subtitle1" : "h6"} gutterBottom>
              Summary
            </Typography>
            <Typography variant={isMobile ? "body2" : "body1"}>
              {performanceData.summary}
            </Typography>
          </Paper>
        </Grid>
      </Grid>
    );
  };

  if (!recapData) {
    return (
      <Box sx={{ p: 3 }}>
        <Typography variant={isMobile ? "body2" : "body1"}>
          No recap data available. Please complete a training session first.
        </Typography>
      </Box>
    );
  }

  return (
    <Box sx={{ flexGrow: 1, p: isMobile ? 2 : 3 }}>
      {loadingInsights ? (
        <Box sx={{ display: "flex", justifyContent: "center", my: 4 }}>
          <CircularProgress />
        </Box>
      ) : errorInsight ? (
        <Typography color="error" variant={isMobile ? "body2" : "body1"}>
          Error fetching analysis: {errorInsight}
        </Typography>
      ) : aiInsight ? (
        renderAnalysis()
      ) : (
        <Button
          variant="contained"
          onClick={handleAnalysisRequest}
          disabled={loadingInsights}
          fullWidth={isMobile}
          size={isMobile ? "medium" : "large"}
        >
          AI Race Performance Analysis
        </Button>
      )}
    </Box>
  );
};

export default TrainingRecapAnalysis;

// const StatCard = ({ icon, title, value, color }) => (
//     <Card
//       sx={{
//         height: "100%",
//         display: "flex",
//         flexDirection: "column",
//         justifyContent: "center",
//         alignItems: "center",
//         p: 2,
//       }}
//     >
//       <Box sx={{ color: color, mb: 1 }}>{icon}</Box>
//       <Typography variant="h6" component="div">
//         {title}
//       </Typography>
//       <Typography variant="h4" component="div">
//         {value}
//       </Typography>
//     </Card>
//   );

//   console.log(
//     formatDistance(convertDistance(recapData.races[0].distance, useKilometers))
//     // useKilometers
//   );
//   //   console.log(
//   //     convertDistance(
//   //       formatDistance(recapData.races[0].distance, useKilometers),
//   //       useKilometers
//   //     )
//   //   );

//   const RaceStatsRecap = () => {
//     return (
//       <Box sx={{ p: 3 }}>
//         <Typography variant="h4" gutterBottom>
//           Training Recap
//         </Typography>
//         <Grid container spacing={3} sx={{ mb: 3 }}>
//           <Grid item xs={12} sm={6} md={3}>
//             <StatCard
//               icon={<DirectionsRun fontSize="large" />}
//               title="Distance"
//               value={formatDistance(
//                 convertDistance(recapData.races[0].distance, useKilometers)
//               )}
//               color="primary.main"
//             />
//           </Grid>
//           <Grid item xs={12} sm={6} md={3}>
//             <StatCard
//               icon={<Favorite fontSize="large" />}
//               title="Avg Heart Rate"
//               value={recapData.races[0].average_heartrate}
//               color="secondary.main"
//             />
//           </Grid>
//           <Grid item xs={12} sm={6} md={3}>
//             <StatCard
//               icon={<TrendingUp fontSize="large" />}
//               title="Avg Pace"
//               value={formatDistance(
//                 convertDistance(recapData.races[0].distance, useKilometers)
//               )}
//               color="success.main"
//             />
//           </Grid>
//           <Grid item xs={12} sm={6} md={3}>
//             <StatCard
//               icon={<LocalDrink fontSize="large" />}
//               title="Hydration"
//               value="Effective"
//               color="info.main"
//             />
//           </Grid>
//         </Grid>

//         {/* <Card>
//           <CardContent>
//             <Typography variant="h6" gutterBottom>
//               Pace Analysis
//             </Typography>
//             <ResponsiveContainer width="100%" height={300}>
//               <LineChart data={paceData}>
//                 <CartesianGrid strokeDasharray="3 3" />
//                 <XAxis
//                   dataKey="km"
//                   label={{
//                     value: "Distance (km)",
//                     position: "insideBottom",
//                     offset: -5,
//                   }}
//                 />
//                 <YAxis
//                   label={{
//                     value: "Pace (min/km)",
//                     angle: -90,
//                     position: "insideLeft",
//                   }}
//                 />
//                 <Tooltip />
//                 <Line type="monotone" dataKey="pace" stroke="#8884d8" />
//               </LineChart>
//             </ResponsiveContainer>
//           </CardContent>
//         </Card> */}
//       </Box>
//     );
//   };
