import React from "react";
import { Box, Typography, Grid, Button, Avatar } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { useTrainingRecap } from "../../../contexts/TrainingRecapContext";
import { formatTime, formatDistance } from "../TrainingRecap";
import { getSvgUrl } from "../../../utils";

const FinalSlide = () => {
  const theme = useTheme();
  const { recapData, useKilometers } = useTrainingRecap();
  const {
    summary_stats: summaryStats,
    kudos_info: kudosInfo,
    comments_info: commentsInfo,
  } = recapData;
  const stravaSvg = getSvgUrl("pwd_strava");

  const totalDistance = useKilometers
    ? summaryStats.total_distance / 1000
    : summaryStats.total_distance / 1609.34;

  const raceDate = new Date(recapData.races[0].start_date);
  const formattedRaceDate = raceDate.toLocaleDateString();

  const uniqueSupporters = Array.from(
    new Set(
      [...kudosInfo.top_kudoers, ...commentsInfo.top_commenters].map(
        (supporter) => JSON.stringify(supporter)
      )
    )
  )
    .map((stringified) => JSON.parse(stringified))
    .slice(0, 6);

  return (
    <Box
      sx={{
        width: "100%",
        height: "100%",
        display: "flex",
        flexDirection: "column",
        position: "relative",
        bgcolor: theme.palette.background.default,
      }}
    >
      <Box
        sx={{
          flexGrow: 1,
          overflowY: "auto",
          padding: "25px",
          //   height: "100%",
          paddingBottom: "100px",
        }}
      >
        <Box
          sx={{
            bgcolor: theme.palette.background.paper,
            borderRadius: theme.shape.borderRadius,
            border: `1px solid ${theme.palette.divider}`,
            p: 3,
            // maxWidth: 400,
            // mx: "auto",
            height: "100%",
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-evenly",
          }}
        >
          <Typography variant="h5" gutterBottom>
            Your Running Journey
          </Typography>
          <Typography variant="subtitle1" gutterBottom>
            Training Recap
          </Typography>
          <Typography variant="body2" gutterBottom>
            Great job on your training!
          </Typography>

          <Box sx={{ my: 2 }}>
            <Typography variant="body2">Your training journey</Typography>
            <Typography variant="body1" color="primary" fontWeight="bold">
              Congrats! You've made great progress!
            </Typography>
          </Box>

          <Grid container spacing={2}>
            <Grid item xs={6}>
              <Typography variant="body2">Training Distance</Typography>
              <Typography variant="h6">
                {formatDistance(totalDistance, useKilometers)}
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography variant="body2">Race Time</Typography>
              <Typography variant="h6">
                {formatTime(recapData.races[0].moving_time)}
              </Typography>
              <Typography variant="h6">{formattedRaceDate}</Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography variant="body2">Total Training Time</Typography>
              <Typography variant="h6">
                {formatTime(summaryStats.total_time)}
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography variant="body2">Total Kudos</Typography>
              <Typography variant="h6">
                {recapData.kudos_info.total_kudos}
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography variant="body2">Total Runs</Typography>
              <Typography variant="h6">{summaryStats.total_runs}</Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography variant="body2">Total Comments</Typography>
              <Typography variant="h6">
                {recapData.comments_info.total_comments}
              </Typography>
            </Grid>
          </Grid>
          <Box sx={{ mt: 3, mb: 2 }}>
            <Typography variant="h6" gutterBottom>
              Share and Tag Your Running Support!
            </Typography>
            <Grid container spacing={2}>
              {uniqueSupporters.map((supporter, index) => (
                <Grid item xs={4} key={index}>
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                    }}
                  >
                    <Avatar
                      sx={{
                        width: 40,
                        height: 40,
                        mb: 1,
                        bgcolor: theme.palette.grey[300],
                      }}
                    >
                      {supporter.name[0]}
                    </Avatar>
                    <Typography variant="caption" align="center">
                      {supporter.name.split(" ")[0]}
                    </Typography>
                  </Box>
                </Grid>
              ))}
            </Grid>
          </Box>
          <Box sx={{ paddingTop: "16px", padding: "16px" }}>
            <img
              src={stravaSvg}
              alt="Strava"
              style={{ width: "100%", height: "auto" }}
            />
          </Box>

          {/* Clear area for controls */}
        </Box>
      </Box>
      <Box
        sx={{
          position: "absolute",
          bottom: 0,
          left: 0,
          right: 0,
          height: 100,
        }}
      />
    </Box>
  );
};

export default FinalSlide;
