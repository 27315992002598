import React, { createContext, useState, useCallback, useRef } from "react";
import axios from "../axiosConfig";

export const AnalysisContext = createContext();

export const AnalysisProvider = ({ children }) => {
  const [analysis, setAnalysis] = useState(null);
  const [status, setStatus] = useState("loading");
  const [error, setError] = useState(null);
  const [taskId, setTaskId] = useState(() => {
    return localStorage.getItem("taskId") || null;
  });

  const isPolling = useRef(false);
  const pollTimeoutRef = useRef(null);
  const initialDelay = 1500; // 6 seconds
  const maxDelay = 30000; // 30 seconds
  const maxAttempts = 60; // 5 minutes total (with exponential backoff)

  const clearPolling = useCallback(() => {
    isPolling.current = false;
    if (pollTimeoutRef.current) {
      clearTimeout(pollTimeoutRef.current);
      pollTimeoutRef.current = null;
    }
  }, []);

  const pollForResults = useCallback(
    async (id, attempt = 0, delay = initialDelay) => {
      if (!isPolling.current) return;

      try {
        const response = await axios.get(`/activities/ai-analysis/${id}`);
        console.log("Poll response:", response.data);

        if (response.data.status === "completed") {
          setAnalysis(response.data.result);
          setStatus("completed");
          clearPolling();
        } else if (response.data.status === "failed") {
          setError("Analysis failed: " + response.data.message);
          setStatus("error");
          clearPolling();
        } else if (attempt < maxAttempts) {
          const nextDelay = Math.min(delay * 2, maxDelay);
          pollTimeoutRef.current = setTimeout(
            () => pollForResults(id, attempt + 1, nextDelay),
            delay
          );
        } else {
          setError("Analysis timed out. Please try again.");
          setStatus("error");
          clearPolling();
        }
      } catch (err) {
        console.error("Error polling for results:", err);
        if (err.response && err.response.status === 404) {
          // Task not found, start a new analysis
          console.log("Task not found. Starting a new analysis.");
          await fetchAnalysis();
        } else {
          setError("Failed to retrieve analysis results");
          setStatus("error");
          clearPolling();
        }
      }
    },
    [clearPolling]
  );

  const fetchAnalysis = useCallback(async () => {
    try {
      const response = await axios.get("/activities/ai-analysis");
      console.log("Fetch response:", response.data); // Debug log
      if (response.data.task_id) {
        setTaskId(response.data.task_id);
        localStorage.setItem("taskId", response.data.task_id);
        isPolling.current = true;
        pollForResults(response.data.task_id);
      } else {
        setAnalysis(response.data);
        setStatus("completed");
      }
    } catch (err) {
      console.error("Error fetching AI analysis:", err); // Detailed error log
      setError(err.response?.data?.detail || "Failed to fetch AI analysis");
      setStatus("error");
    }
  });

  const retryAnalysis = useCallback(() => {
    clearPolling();
    setError(null);
    setStatus("loading");
    fetchAnalysis();
  }, [clearPolling, fetchAnalysis]);

  return (
    <AnalysisContext.Provider
      value={{
        analysis,
        setAnalysis,
        status,
        setStatus,
        error,
        setError,
        taskId,
        setTaskId: (id) => {
          localStorage.setItem("taskId", id);
          setTaskId(id);
        },
        pollForResults,
        clearPolling,
        isPolling,
        pollTimeoutRef,
        fetchAnalysis,
        retryAnalysis,
      }}
    >
      {children}
    </AnalysisContext.Provider>
  );
};
