import React, { useState, useEffect, useMemo } from "react";
import { AnimatePresence } from "framer-motion";
import {
  Typography,
  Button,
  Box,
  CircularProgress,
  IconButton,
} from "@mui/material";
import { ArrowBack, ArrowForward } from "@mui/icons-material";
import { useTrainingRecap } from "../../contexts/TrainingRecapContext";
import CommentsSlide from "./Slides/CommentsSlide";
import KudosSlide from "./Slides/KudosSlide";
import SlideWrapper from "./Slides/SlideWrapper";
import RecapSlide from "./Slides/RecapSlide";
import RaceSlide from "./Slides/RaceSlide";
import FinalSlide from "./Slides/FinalSlide";

const TrainingRecapVideo = ({ onClose }) => {
  const { recapData, useKilometers } = useTrainingRecap();
  const [currentSlide, setCurrentSlide] = useState(0);
  const [isPlaying, setIsPlaying] = useState(true);

  const slides = useMemo(() => {
    if (!recapData) return [];
    return [
      {
        content: ({ recapData, isVisible }) => (
          <RecapSlide
            recapData={recapData}
            isVisible={isVisible}
            useKilometers={useKilometers}
          />
        ),
      },
      {
        content: ({ recapData, isVisible }) => (
          <CommentsSlide recapData={recapData} isVisible={isVisible} />
        ),
      },
      {
        content: ({ recapData, isVisible }) => (
          <KudosSlide recapData={recapData} isVisible={isVisible} />
        ),
      },
      {
        content: ({ recapData, isVisible }) => (
          <RaceSlide
            recapData={recapData}
            isVisible={isVisible}
            useKilometers={useKilometers}
          />
        ),
      },
      {
        content: () => <FinalSlide />,
      },
    ];
  }, [recapData, useKilometers]);

  useEffect(() => {
    let timer;
    if (isPlaying && slides.length > 0) {
      timer = setTimeout(() => {
        setCurrentSlide((prevSlide) => (prevSlide + 1) % slides.length);
      }, 5000);
    }
    return () => clearTimeout(timer);
  }, [currentSlide, slides.length, isPlaying]);

  if (!recapData) {
    return <CircularProgress />;
  }

  const handlePrevSlide = () => {
    setCurrentSlide(
      (prevSlide) => (prevSlide - 1 + slides.length) % slides.length
    );
  };

  const handleNextSlide = () => {
    setCurrentSlide((prevSlide) => (prevSlide + 1) % slides.length);
  };

  const togglePlayPause = () => {
    setIsPlaying(!isPlaying);
  };

  return (
    <Box
      sx={{
        position: "fixed",
        top: 0,
        left: 0,
        right: 0,
        bottom: 20,
        bgcolor: "background.paper",
        zIndex: 1300,
        p: 3,
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <AnimatePresence>
        {slides.map((slide, index) => (
          <SlideWrapper
            key={index}
            background={slide.background}
            isActive={currentSlide === index}
            onClose={onClose}
          >
            <Typography variant="h3" gutterBottom>
              {slide.title}
            </Typography>
            {React.createElement(slide.content, {
              recapData,
              isVisible: currentSlide === index,
            })}
          </SlideWrapper>
        ))}
      </AnimatePresence>

      <Box
        sx={{
          position: "absolute",
          bottom: 20,
          left: 0,
          right: 0,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <IconButton onClick={handlePrevSlide}>
          <ArrowBack />
        </IconButton>
        <Button onClick={togglePlayPause} sx={{ mx: 2 }}>
          {isPlaying ? "Pause" : "Play"}
        </Button>
        <IconButton onClick={handleNextSlide}>
          <ArrowForward />
        </IconButton>
        <Button
          onClick={onClose}
          sx={{
            mx: 2,
            position: "absolute",
            right: 10,
          }}
        >
          Close
        </Button>
      </Box>
    </Box>
  );
};

export default React.memo(TrainingRecapVideo);
