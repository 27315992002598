import React from "react";
import { motion } from "framer-motion";
import { Button } from '@mui/material';

const SlideWrapper = ({ children, background, isActive, onClose }) => {
  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: isActive ? 1 : 0 }}
      exit={{ opacity: 0 }}
      transition={{ duration: 0.5 }}
      style={{
        position: "absolute",
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        background: background,
        overflow: "hidden",
      }}
    >
      {children}
    </motion.div>
  );
};

export default SlideWrapper;
