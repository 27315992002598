import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

const StravaRedirect = () => {
  const navigate = useNavigate();
  const [userId, setUserId] = useState(null); // State to store user ID

  useEffect(() => {
    captureUserId();
  }, []);

  const captureUserId = () => {
    const params = new URLSearchParams(window.location.search);
    const id = params.get("user_id"); // Get user_id from URL
    if (id) {
      setUserId(id); // Store user ID in state
      // console.log("Captured user ID:", id); // Log the captured user ID
      navigate(`/athlete?user_id=${id}`); // Redirect to /athlete with user_id as a query parameter
    } else {
      console.log("user id not found");
      // navigate("/athlete"); // Redirect to /athlete without user_id if not found
    }
  };

  return <div>Redirecting...</div>;
};

export default StravaRedirect;
