import React, { useState, useEffect, useCallback } from "react";
import { useLocation } from "react-router-dom";
import axios from "axios";
import {
  Avatar,
  Typography,
  Grid,
  Chip,
  List,
  ListItem,
  ListItemText,
  ListItemAvatar,
  CircularProgress,
  Box,
} from "@mui/material";
import { useAthlete } from "../contexts/AthleteContext";
import { useGatheringData } from "../contexts/GatheringDataContext";
import { debounce } from "lodash";
import { getSvgUrl } from "../utils";
import { useTheme, useMediaQuery } from "@mui/material";

const Athlete = () => {
  const { athlete, setAthlete } = useAthlete();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const stravaOrange = process.env.REACT_APP_STRAVA_ORANGE;
  const AchievementSvgUrl = getSvgUrl("achievement");
  const UserSvgUrl = getSvgUrl("user");
  const TrophySvgUrl = getSvgUrl("trophy");
  const FlagSvgUrl = getSvgUrl("flag");
  const HeartSvgUrl = getSvgUrl("heart_rate");
  const RunningSvgUrl = getSvgUrl("running");
  const theme = useTheme();
  const { gatheringData, setGatheringData } = useGatheringData(); // Use the context
  // const [gatheringData, setGatheringData] = useState(false);
  const [gatheringDataStatus, setGatheringDataStatus] = useState(false);
  const [pollingInterval, setPollingInterval] = useState(null);
  const location = useLocation();
  const [userId, setUserId] = useState(null);

  const baseApiUrl = process.env.REACT_APP_API_URL;
  // Capture user ID from URL
  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const id = params.get("user_id"); // Get user_id from URL
    if (id) {
      setUserId(id); // Store user ID in state
    }
  }, [location]);

  const fetchAthleteData = useCallback(
    debounce(async (userId) => {
      try {
        const response = await axios.get(`${baseApiUrl}/api/athlete`, {
          params: { user_id: userId }, // Pass user ID as a query parameter
        });
        setAthlete(response.data.athlete);
        // console.log(response.data);
        // console.log(response.data.activity_fetch_task_id);
        fetchTaskStatus(response.data.activity_fetch_task_id);
      } catch (err) {
        if (err.response && err.response.status === 500) {
          // Handle 500 error
          setError("There is an error. Please come back in 15 minutes."); // Set error message
        } else {
          console.error("Failed to fetch athlete data", err);
          console.log(baseApiUrl);
        }
      }
    }, 300),
    [baseApiUrl, setAthlete]
  );

  // Fetch athlete data when userId is set
  useEffect(() => {
    if (userId) {
      fetchAthleteData(userId); // Fetch athlete data using the user ID
    }
  }, [userId]); // Dependency on userId

  const endTime = Date.now() + 7200000;
  const fetchTaskStatus = async (taskId) => {
    console.log(taskId);
    setGatheringData(true); // Set gathering data to true
    try {
      while (Date.now() < endTime) {
        // const response = await fetch(`/api/fetch-status/${taskId}`);
        const response = await fetch(
          `${baseApiUrl}/api/fetch-status/${taskId}`
        );
        if (!response.ok) {
          throw new Error(`Error: ${response.statusText}`);
        }
        const data = await response.json();
        console.log(data);
        setGatheringDataStatus(data);
        setError(null); // Clear any previous errors

        // Check if the status is completed
        if (data.status === "completed") {
          setGatheringDataStatus(data);
          // console.log(gatheringDataStatus);
          console.log("Polling stopped: Status is completed.");
          break; // Exit the loop if completed
        }
        // Check if the status is rate_limited
        if (data.status === "rate_limited") {
          console.log(`Rate limited. Waiting for ${data.wait_time} seconds.`);
          await new Promise((resolve) =>
            setTimeout(resolve, data.wait_time * 1000)
          ); // Wait for the specified wait_time
          continue; // Skip to the next iteration to poll again
        }

        // Optionally, you can add a delay between polls
        await new Promise((resolve) => setTimeout(resolve, 1000)); // Wait 1 second before next poll
      }
    } catch (err) {
      setError(err.message);
    } finally {
      setGatheringData(false); // Reset gathering data state
    }
  };

  useEffect(() => {
    // Optional: Set a maximum polling interval to prevent excessive requests
    const interval = setInterval(() => {
      if (gatheringData) {
        console.log("Still gathering data...");
      }
    }, 60000); // Log every minute

    setPollingInterval(interval);

    return () => clearInterval(interval); // Cleanup on unmount
  }, [gatheringData]);

  if (!athlete) {
    return (
      <Box>
        <CircularProgress />
        {error && <Typography color="error">{error}</Typography>}{" "}
        {/* Display error message if it exists */}
      </Box>
    );
  }

  return (
    <Box sx={{ backgroundColor: theme.palette.background.default }}>
      {gatheringData && gatheringDataStatus && (
        <Box
          sx={{
            backgroundColor: theme.palette.background.paper,
            borderRadius: 1,
            overflow: "hidden",
            mb: 2,
            p: 2,
          }}
        >
          <Typography variant="h5">Gathering Data...</Typography>
          <Typography variant="h6">
            Due to API restrictions this might take up to 2 hours
          </Typography>
          <Typography variant="body2" color="textSecondary">
            Status: {gatheringDataStatus.status}
          </Typography>
          <Typography variant="body2" color="textSecondary">
            Progress: {gatheringDataStatus.progress}%
          </Typography>
          <Typography variant="body2" color="textSecondary">
            Processed Detailed Fetch:{" "}
            {gatheringDataStatus.processed_detailed_fetch}
          </Typography>
          <Typography variant="body2" color="textSecondary">
            Processed Comments Fetch:{" "}
            {gatheringDataStatus.processed_comments_fetch}
          </Typography>
          <Typography variant="body2" color="textSecondary">
            Processed Kudos Fetch: {gatheringDataStatus.processed_kudos_fetch}
          </Typography>
          <Typography variant="body2" color="textSecondary">
            Remaining Detailed Fetch:{" "}
            {gatheringDataStatus.remaining_detailed_fetch}
          </Typography>
          <Typography variant="body2" color="textSecondary">
            Remaining Comments Fetch:{" "}
            {gatheringDataStatus.remaining_comments_fetch}
          </Typography>
          <Typography variant="body2" color="textSecondary">
            Remaining Kudos Fetch: {gatheringDataStatus.remaining_kudos_fetch}
          </Typography>
          <Typography variant="body2" color="textSecondary">
            Total Activities: {gatheringDataStatus.total_activities}
          </Typography>
          <Typography variant="body2" color="textSecondary">
            Estimated Time Remaining:{" "}
            {Math.max(0, Math.floor((endTime - Date.now()) / 60000))} minutes
          </Typography>
        </Box>
      )}
      <Box
        sx={{
          backgroundColor: theme.palette.background.paper,
          borderRadius: 1,
          overflow: "hidden",
          mb: 2,
        }}
      >
        <Box sx={{ display: "flex", alignItems: "center", p: 2 }}>
          <Avatar
            src={athlete.profile}
            alt={`${athlete.firstname} ${athlete.lastname}`}
            sx={{ width: 80, height: 80, mr: 2 }}
          />
          <Box>
            <Typography variant="h5">{`${athlete.firstname} ${athlete.lastname}`}</Typography>
            <Typography variant="body2" color="textSecondary">
              {athlete.username}
            </Typography>
          </Box>
        </Box>

        <Box sx={{ p: 2 }}>
          <Typography variant="h6" gutterBottom>
            Personal Information
          </Typography>
          <List disablePadding>
            <ListItem>
              <ListItemAvatar>
                <Avatar
                  sx={{
                    bgcolor: "background.paper",
                    color: "text.primary",
                    marginRight: 1,
                  }}
                >
                  <img
                    src={UserSvgUrl}
                    alt="User"
                    style={{ width: 24, height: 24, filter: stravaOrange }}
                  />
                </Avatar>
              </ListItemAvatar>
              <ListItemText
                primary="Gender"
                secondary={athlete.sex === "M" ? "Male" : "Female"}
                primaryTypographyProps={{ variant: "body1" }}
                secondaryTypographyProps={{
                  variant: "body2",
                  color: "textSecondary",
                }}
              />
            </ListItem>
            <ListItem>
              <ListItemAvatar>
                <Avatar
                  sx={{ bgcolor: "background.paper", color: "text.primary" }}
                >
                  <img
                    src={AchievementSvgUrl}
                    alt="Member Date"
                    style={{ width: 24, height: 24, filter: stravaOrange }}
                  />
                </Avatar>
              </ListItemAvatar>
              <ListItemText
                primary="Member Since"
                secondary={new Date(athlete.created_at).toLocaleDateString()}
                primaryTypographyProps={{ variant: "body1" }}
                secondaryTypographyProps={{
                  variant: "body2",
                  color: "textSecondary",
                }}
              />
            </ListItem>
            <ListItem>
              <ListItemAvatar>
                <Avatar
                  sx={{ bgcolor: "background.paper", color: "text.primary" }}
                >
                  <img
                    src={FlagSvgUrl}
                    alt="Location"
                    style={{ width: 24, height: 24, filter: stravaOrange }}
                  />
                </Avatar>
              </ListItemAvatar>
              <ListItemText
                primary="Location"
                secondary={`${athlete.city}, ${athlete.state}`}
                primaryTypographyProps={{ variant: "body1" }}
                secondaryTypographyProps={{
                  variant: "body2",
                  color: "textSecondary",
                }}
              />
            </ListItem>
          </List>
        </Box>

        <Box sx={{ p: 2 }}>
          <Typography variant="h6" gutterBottom>
            Statistics
          </Typography>
          <List disablePadding>
            <ListItem>
              <ListItemAvatar>
                <Avatar
                  sx={{ bgcolor: "background.paper", color: "text.primary" }}
                >
                  <img
                    src={TrophySvgUrl}
                    alt="Premium"
                    style={{ width: 24, height: 24, filter: stravaOrange }}
                  />
                </Avatar>
              </ListItemAvatar>
              <ListItemText
                primary="Premium Member"
                secondary={athlete.premium ? "Yes" : "No"}
                primaryTypographyProps={{ variant: "body1" }}
                secondaryTypographyProps={{
                  variant: "body2",
                  color: "textSecondary",
                }}
              />
            </ListItem>
            <ListItem>
              <ListItemAvatar>
                <Avatar
                  sx={{ bgcolor: "background.paper", color: "text.primary" }}
                >
                  <img
                    src={HeartSvgUrl}
                    alt="Connections"
                    style={{ width: 24, height: 24, filter: stravaOrange }}
                  />
                </Avatar>
              </ListItemAvatar>
              <ListItemText
                primary="Connections"
                secondary={`${athlete.follower_count} followers, ${athlete.friend_count} friends`}
                primaryTypographyProps={{ variant: "body1" }}
                secondaryTypographyProps={{
                  variant: "body2",
                  color: "textSecondary",
                }}
              />
            </ListItem>
          </List>
        </Box>
      </Box>

      <Box
        sx={{
          backgroundColor: theme.palette.background.paper,
          borderRadius: 1,
          p: 2,
          mb: 2,
        }}
      >
        <Typography variant="h6" gutterBottom>
          Clubs
        </Typography>
        <Grid container spacing={1}>
          {athlete.clubs.map((club) => (
            <Grid item key={club.id}>
              <Chip
                avatar={<Avatar alt={club.name} src={club.profile_medium} />}
                label={club.name}
                variant="outlined"
                sx={{ fontWeight: "500" }}
              />
            </Grid>
          ))}
        </Grid>
      </Box>

      <Box
        sx={{
          backgroundColor: theme.palette.background.paper,
          borderRadius: 1,
          p: 2,
        }}
      >
        <Typography variant="h6" gutterBottom>
          Shoes
        </Typography>
        <List disablePadding>
          {athlete.shoes.map((shoe) => (
            <ListItem key={shoe.id}>
              <ListItemAvatar>
                <Avatar
                  sx={{
                    bgcolor: "background.paper",
                    color: "text.primary",
                  }}
                >
                  <img
                    src={RunningSvgUrl}
                    alt="Shoes"
                    style={{ width: 24, height: 24, filter: stravaOrange }}
                  />
                </Avatar>
              </ListItemAvatar>
              <ListItemText
                primary={shoe.name}
                secondary={`Distance: ${shoe.converted_distance} miles | ${
                  shoe.retired ? "Retired" : "Active"
                }`}
                primaryTypographyProps={{ variant: "body1" }}
                secondaryTypographyProps={{
                  variant: "body2",
                  color: "textSecondary",
                }}
              />
            </ListItem>
          ))}
        </List>
      </Box>
    </Box>
  );
};

export default Athlete;
