import React, { useState, useEffect, useCallback } from "react";
import {
  Typography,
  Button,
  CircularProgress,
  TextField,
  Box,
  Container,
} from "@mui/material";
import axios from "axios";
import Athlete from "./Athlete";
import { useAthlete } from "../contexts/AthleteContext";
import { ThemeProvider } from "@mui/material/styles";
import stravaTheme from "../theme"; // Assuming your theme is stored here

const Home = () => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [loading, setLoading] = useState(true);
  const [email, setEmail] = useState("");
  const [betaCode, setBetaCode] = useState(""); // New state for beta code
  const [message, setMessage] = useState(""); // State for the response message
  const [approved, setApproved] = useState(false); // New state to track form submission
  const { athlete } = useAthlete();
  const apiUrl = process.env.REACT_APP_API_URL;
  const betaEmailLambda = process.env.REACT_APP_BETA_EMAIL_LAMBDA;

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
  };

  const handleBetaCodeChange = (e) => {
    setBetaCode(e.target.value);
  };

  const handleSubmit = async () => {
    try {
      const response = await axios.post(
        betaEmailLambda,
        {
          email: email,
          betaCode: betaCode || null,
        },
        {
          headers: {
            "Content-Type": "application/json", // Explicitly set Content-Type
          },
        }
      );
      console.log("Raw response from API:", response.data);

      // Parse the stringified JSON body
      const responseBody = JSON.parse(response.data.body);

      setMessage(responseBody.message);

      if (responseBody.allowAccess) {
        setApproved(true);
      }
    } catch (error) {
      console.error("Error submitting email:", error);
      setMessage("An error occurred while submitting your email.");
    }
  };

  const checkAuthStatus = useCallback(async () => {
    if (!athlete || !athlete.strava_id) {
      console.error("Athlete or strava_id is not available");
      setLoading(false);
      return;
    }

    try {
      const response = await axios.get(`${apiUrl}/api/auth/status`, {
        params: { user_id: athlete.strava_id },
      });
      setIsAuthenticated(response.data.isAuthenticated);
    } catch (error) {
      console.error("Failed to check auth status:", error);
    } finally {
      setLoading(false);
    }
  }, [athlete, apiUrl]);

  useEffect(() => {
    if (athlete) {
      checkAuthStatus();
    } else {
      console.info("Athlete is not available");
      setLoading(false);
    }
  }, [athlete, checkAuthStatus]);

  const handleConnectStrava = () => {
    const clientId = process.env.REACT_APP_STRAVA_CLIENT_ID;
    const redirectApiUrl = process.env.REACT_APP_STRAVA_REDIRECT_URI;
    const redirectUri = encodeURIComponent(redirectApiUrl);

    const scope = "read,activity:read_all,profile:read_all";

    window.location.href = `https://www.strava.com/oauth/authorize?client_id=${clientId}&redirect_uri=${redirectUri}&response_type=code&scope=${scope}`;
  };

  if (loading) {
    return <CircularProgress />;
  }

  return (
    <ThemeProvider theme={stravaTheme}>
      <Container
        maxWidth="sm"
        sx={{
          bgcolor: "background.default",
          padding: stravaTheme.customSpacing.mobilePadding,
          minHeight: "100vh",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        {isAuthenticated ? (
          <Athlete />
        ) : (
          <Box textAlign="center">
            <Typography variant="h4" gutterBottom>
              Welcome to StravaWrapped
            </Typography>
            <Typography variant="h6" gutterBottom>
              Recap your training!
            </Typography>
            <Typography variant="body1" gutterBottom>
              spotify wrapped + your training plan = this
            </Typography>
            <Typography variant="h5" gutterBottom>
              Join the Waitlist!
            </Typography>
            <TextField
              label="Enter your email"
              variant="outlined"
              fullWidth
              value={email}
              onChange={handleEmailChange}
              sx={{
                mb: 2,
                "& .MuiOutlinedInput-root": {
                  "& fieldset": {
                    borderColor: stravaTheme.palette.primary.main,
                  },
                  "&:hover fieldset": {
                    borderColor: stravaTheme.palette.warning.main,
                  },
                },
              }}
            />
            <TextField
              label="Beta Code (Optional)"
              variant="outlined"
              fullWidth
              value={betaCode}
              onChange={handleBetaCodeChange}
              sx={{
                mb: 2,
                "& .MuiOutlinedInput-root": {
                  "& fieldset": {
                    borderColor: stravaTheme.palette.primary.main,
                  },
                  "&:hover fieldset": {
                    borderColor: stravaTheme.palette.warning.main,
                  },
                },
              }}
            />
            <Button
              variant="contained"
              color="primary"
              fullWidth
              onClick={handleSubmit}
              sx={{
                boxShadow: stravaTheme.shadows[1],
                mb: 4, // Add margin below the submit button
              }}
            >
              Submit
            </Button>

            {/* Display the response message */}
            {message && (
              <Typography variant="body1" color="textPrimary">
                {message}
              </Typography>
            )}

            {/* Conditionally render "Connect with Strava" button after email is submitted */}
            {approved && (
              <Button
                variant="contained"
                color="primary"
                fullWidth
                onClick={handleConnectStrava}
                sx={{
                  boxShadow: stravaTheme.shadows[1],
                }}
              >
                Connect with Strava
              </Button>
            )}
          </Box>
        )}
      </Container>
    </ThemeProvider>
  );
};

export default Home;

// import React, { useState, useEffect, useCallback } from "react";
// import { Typography, Button, CircularProgress } from "@mui/material";
// import axios from "axios";
// import Athlete from "./Athlete";
// import { useAthlete } from "../contexts/AthleteContext";

// const Home = () => {
//   const [isAuthenticated, setIsAuthenticated] = useState(false);
//   const [loading, setLoading] = useState(true);
//   const { athlete, setAthlete } = useAthlete();

//   const apiUrl = process.env.REACT_APP_API_URL;

//   const [email, setEmail] = useState('');

//   const handleEmailChange = (e) => {
//     setEmail(e.target.value);
//   };

//   const handleWaitlistSubmit = () => {
//     console.log('Email submitted:', email);
//     // You can integrate the submission logic here
//   };

//   const checkAuthStatus = useCallback(async () => {
//     if (!athlete || !athlete.strava_id) {
//       // Add this check
//       console.error("Athlete or strava_id is not available");
//       setLoading(false); // Ensure loading is set to false
//       return; // Exit the function early
//     }

//     try {
//       const response = await axios.get(`${apiUrl}/api/auth/status`, {
//         params: { user_id: athlete.strava_id },
//       });
//       setIsAuthenticated(response.data.isAuthenticated);
//     } catch (error) {
//       console.error("Failed to check auth status:", error);
//     } finally {
//       setLoading(false);
//     }
//   }, [athlete]); // No changes needed here

//   useEffect(() => {
//     if (athlete) {
//       // Uncomment and ensure athlete is defined
//       checkAuthStatus();
//     } else {
//       console.info("Athlete is not available");
//       setLoading(false);
//     }
//   }, [athlete, checkAuthStatus]); // Add checkAuthStatus as a dependency

//   const handleConnectStrava = () => {
//     const clientId = process.env.REACT_APP_STRAVA_CLIENT_ID;
//     const redirectApiUrl = process.env.REACT_APP_STRAVA_REDIRECT_URI;
//     const redirectUri = encodeURIComponent(
//       // "http://localhost:8000/api/auth/strava/callback"
//       // "https://www.api.stravawrapped.com/api/auth/strava/callback"
//       redirectApiUrl
//     );

//     console.log(redirectUri);
//     const scope = "read,activity:read_all,profile:read_all";

//     window.location.href = `https://www.strava.com/oauth/authorize?client_id=${clientId}&redirect_uri=${redirectUri}&response_type=code&scope=${scope}`;
//   };

//   if (loading) {
//     return <CircularProgress />;
//   }

//   return (
//     <div>
//       {isAuthenticated ? (
//         <Athlete />
//       ) : (
//         <div>
//           <Typography variant="h4">Welcome to Strava Analyzer</Typography>
//           <Typography variant="body1">
//             Connect your Strava account to get started
//           </Typography>
//           <Button
//             variant="contained"
//             color="primary"
//             onClick={handleConnectStrava}
//           >
//             Connect with Strava
//           </Button>
//         </div>
//       )}
//     </div>
//   );
// };

// export default Home;
