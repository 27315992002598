import React, { useState } from "react";
import {
  Paper,
  Typography,
  TextField,
  Button,
  Grid,
  Box,
  CircularProgress,
} from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import { useTrainingRecap } from "../../contexts/TrainingRecapContext";
import { useAthlete } from "../../contexts/AthleteContext"; // Assume this exists
import TrainingRecap from "./TrainingRecap";
import TrainingRecapAnalysis from "./TrainingRecapAnalysis";
import { useTheme, useMediaQuery } from "@mui/material";

const TrainingRecapContainer = () => {
  const { fetchRecapData, recapData, loading } = useTrainingRecap();
  const { athlete } = useAthlete();
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [isFiltered, setIsFiltered] = useState(false);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const isFutureDate = (date) => dayjs().isBefore(date, "day");

  const isFormValid = startDate && endDate && !isFutureDate(endDate) && athlete;

  const handleStartDateChange = (newValue) => {
    // Set the time to midnight (12:00 AM) on the selected start date
    setStartDate(newValue.startOf("day"));
  };

  const handleEndDateChange = (newValue) => {
    const now = dayjs();
    let adjustedEndDate;

    if (newValue.isSame(now, "day")) {
      // If the end date is today, set the time to the current time
      adjustedEndDate = newValue
        .set("hour", now.hour())
        .set("minute", now.minute())
        .set("second", now.second());
    } else {
      // Otherwise, set the time to 11:59 PM
      adjustedEndDate = newValue.endOf("day");
    }

    setEndDate(adjustedEndDate);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    if (!isFormValid) return;

    const filterData = {
      strava_id: athlete.strava_id,
      startDate: startDate.toISOString(),
      endDate: endDate.toISOString(),
    };
    fetchRecapData(filterData);
    // fetchRecapData();
    setIsFiltered(true);
  };

  const FilterForm = () => (
    <Box
      component="form"
      onSubmit={handleSubmit}
      noValidate
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        width: "100%",
        gap: 2,
      }}
    >
      <Grid
        container
        spacing={2}
        justifyContent="center"
        alignItems="center"
        sx={{
          flexDirection: { xs: "column", sm: "row" },
        }}
      >
        <Grid item xs={12} sm="auto">
          <DatePicker
            label="Start of Your Training"
            value={startDate}
            onChange={handleStartDateChange}
            minDate={dayjs(athlete.created_at)} // Updated to use athlete.created_at
            maxDate={dayjs().subtract(1, "day")}
            slots={{
              textField: (params) => (
                <TextField
                  {...params}
                  fullWidth
                  sx={{
                    "& .MuiInputBase-root": {
                      padding: "10px", // Adjust padding for inputs
                    },
                    "& .MuiOutlinedInput-notchedOutline": {
                      borderColor: theme.palette.grey[300], // Adjust border color to be more subtle
                    },
                  }}
                />
              ),
            }}
          />
        </Grid>
        <Grid item xs={12} sm="auto">
          <DatePicker
            label="Date of Your Race"
            value={endDate}
            onChange={handleEndDateChange}
            minDate={startDate}
            maxDate={dayjs()}
            slots={{
              textField: (params) => (
                <TextField
                  {...params}
                  fullWidth
                  sx={{
                    "& .MuiInputBase-root": {
                      padding: "10px", // Adjust padding for inputs
                    },
                    "& .MuiOutlinedInput-notchedOutline": {
                      borderColor: theme.palette.grey[300], // Adjust border color to be more subtle
                    },
                  }}
                />
              ),
            }}
          />
        </Grid>
        <Grid
          item
          xs={12}
          sm="auto"
          sx={{
            width: { xs: "100%", sm: "auto" },
            order: { xs: 2, sm: 1 },
            mt: { xs: 2, sm: 0 },
          }}
        >
          <Button
            type="submit"
            variant="contained"
            color="primary"
            fullWidth={isMobile}
            sx={{
              minWidth: "120px",
              padding: "10px 16px", // Adjusted padding for buttons
              fontSize: theme.typography.button.fontSize, // Consistent font size for buttons
              textTransform: "uppercase",
              fontWeight: theme.typography.fontWeightBold,
            }}
            disabled={!isFormValid}
          >
            Apply Filters
          </Button>
        </Grid>
      </Grid>
    </Box>
  );

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <Paper
        elevation={isMobile ? 0 : 1}
        sx={{
          padding: isMobile ? "8px" : "16px",
          margin: isMobile ? "8px 0" : "16px 0",
          boxShadow: isMobile ? "none" : undefined,
          borderRadius: "8px", // Match Strava's card style
          backgroundColor: theme.palette.background.paper,
        }}
      >
        <Box
          sx={{
            mb: { xs: 1, sm: 2 },
            width: "100%",
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          {/* <Typography variant={isMobile ? "h6" : "h3"} gutterBottom> */}
          <Typography variant={isMobile ? "h6" : "h5"} fontWeight={600}>
            Training Recap
          </Typography>
          <Button
            size="small"
            onClick={() => setIsFiltered(false)}
            variant="text"
            color="error"
            sx={{
              textTransform: "uppercase",
              fontWeight: "bold",
              fontSize: isMobile ? "0.75rem" : "0.875rem",
              letterSpacing: "0.5px",
            }}
          >
            Change Filters
          </Button>
        </Box>

        {!isFiltered && <FilterForm isMobile={isMobile} />}
        {isFiltered && (
          <>
            <Box
              sx={{
                mb: { xs: 1, sm: 2 },
                width: "100%",
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
              }}
            >
              <Typography variant={isMobile ? "body2" : "subtitle1"}>
                Showing data from {startDate?.format("MM/DD/YYYY HH:mm A")} to{" "}
                {endDate?.format("MM/DD/YYYY HH:mm A")}
              </Typography>
            </Box>
            {loading ? (
              <CircularProgress />
            ) : recapData ? (
              <>
                <TrainingRecapAnalysis />
                <TrainingRecap />
              </>
            ) : (
              <Typography variant={isMobile ? "body2" : "body1"}>
                No data available for the selected period.
              </Typography>
            )}
          </>
        )}
      </Paper>
    </LocalizationProvider>
  );
};

export default TrainingRecapContainer;
