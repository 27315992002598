import React, { useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { Typography, CircularProgress } from "@mui/material";
import axios from "axios";

const StravaCallback = () => {
  const [status, setStatus] = useState("Processing");
  const navigate = useNavigate();
  const location = useLocation();

  const apiUrl = process.env.REACT_APP_API_URL;
  const redirectApiUrl = process.env.REACT_APP_STRAVA_REDIRECT_URI;

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const code = searchParams.get("code");

    if (code) {
      handleStravaCallback(code);
    } else {
      setStatus("Error: No authorization code received");
    }
  }, [location]);

  const handleStravaCallback = async (code) => {
    try {
      await axios.get(`${apiUrl}/api/auth/strava/callback?code=${code}`);
      setStatus("Successfully connected with Strava");
      setTimeout(() => navigate("/"), 2000); // Redirect to home page after 2 seconds
    } catch (error) {
      setStatus("Error: Failed to connect with Strava");
    }
  };

  return (
    <div>
      <Typography variant="h5">{status}</Typography>
      {status.startsWith("Processing") && <CircularProgress />}
    </div>
  );
};

export default StravaCallback;
