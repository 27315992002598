import React, { createContext, useState, useContext, useCallback } from "react";
import { debounce } from "lodash";
import axios from "axios";

// Create the context
const AthleteContext = createContext(null);

// Custom hook to use the AthleteContext
export const useAthlete = () => {
  return useContext(AthleteContext);
};

// Create the provider component
export const AthleteProvider = ({ children }) => {
  const [athlete, setAthlete] = useState(null);
  const [initalData, setInitalData] = useState(null);

  const apiUrl = process.env.REACT_APP_API_URL;

  // This is where you would update the athlete state with the data returned from the API or other source
  // setting state within Athlete.js
  // const setAthleteData = (data) => {
  //     setAthlete(data);
  // };

  const fetchAthleteData = useCallback(
    debounce(async () => {
      try {
        const response = await axios.get(`${apiUrl}/api/athlete`);
        setAthlete(response.data.athlete);
        console.log(response.data);
      } catch (err) {
        console.error("Failed to fetch athlete data", err);
      }
    }, 300),
    [apiUrl, setAthlete]
  );

  return (
    <AthleteContext.Provider
      value={{ athlete, setAthlete, initalData, setInitalData }}
    >
      {children}
    </AthleteContext.Provider>
  );
};
